import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addCourse, courseImg } from "../store/CourseSlice";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import { useNavigate } from "react-router-dom";
import { Editor } from '../components/Editor';

function AddNewCourse() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [inputs, setInputs] = useState({
    name: "",
    description: "",
    imageUrl: "",
    price: "",
    commission: "",
    orderNumber: "",
    isActive: false,
  });

  const handelInputChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const handleEditorChange = (editorvalue) => {
    setInputs((prevInputs) => ({ ...prevInputs, description: editorvalue }));
  };

  const handelUploadPicture = (e) => {
    setLoading(true);
    const { name, files } = e.target;
    const formData = new FormData();
    console.log(inputs)
    formData.append("file", e.target.files[0]);
    dispatch(courseImg(formData))
      .unwrap()
      .then((res) => {
        setLoading(false);
        setInputs((prevInputs) => ({
          ...prevInputs,
          imageUrl: res.dbPath, // Update the specific input with the file object
        }));
      })
      .catch((err) => {
        setLoading(false);
        toast.error(`${err.response.data.title}`);
      });
  };

  const saveData = (e) => {
    e.preventDefault();
    setLoading(true);
    inputs.commission = parseInt(inputs.commission);
    inputs.price = parseInt(inputs.price);
    dispatch(addCourse(inputs))
      .unwrap()
      .then((res) => {
        toast.success("تم الاضافه بنجاح");
        setLoading(false);
        navigate("/dashboard/courses");
      })
      .catch((err) => {
        toast.error(err.response.data.title);
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? <Loading open={true} /> : null}
      <div className="form_style">
        <div className="form_header">
          <h5>إضافة كورس جديد</h5>
          <p>يرجي ادخال البيانات التالية</p>
        </div>
        <form action="" onSubmit={saveData}>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="form-group">
                <input
                  type="file"
                  onChange={handelUploadPicture}
                  name="name"
                  id=""
                  className="form-control"
                  placeholder="الصوره"
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  onChange={handelInputChange}
                  value={inputs.name}
                  id=""
                  className="form-control"
                  placeholder="الاسم"
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="form-group">
                <input
                  type="number"
                  min={0}
                  onChange={handelInputChange}
                  value={inputs.price}
                  name="price"
                  id=""
                  className="form-control"
                  placeholder="السعر"
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="form-group">
                <input
                  type="number"
                  min={0}
                  onChange={handelInputChange}
                  value={inputs.commission}
                  name="commission"
                  id=""
                  className="form-control"
                  placeholder="النسبه"
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="form-group">
                <select name="isActive" className="form-control" id="" onChange={handelInputChange}>
                  <option value={true}>نشط</option>
                  <option value={false}>غير نشط</option>
                </select>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="form-group">
                <label htmlFor="">الترتيب</label>
                <input
                  type="number"
                  min={0}
                  onChange={handelInputChange}
                  value={inputs.orderNumber}
                  name="orderNumber"
                  id=""
                  className="form-control"
                  placeholder="الترتيب"
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="form-group">
                {/* <textarea name="description"
                  id=""
                  onChange={handelInputChange}
                  value={inputs.description}
                  className="form-control"
                  placeholder="تفاصيل بالكورس">
                </textarea> */}

                <Editor
                  handleChange={handleEditorChange}
                  editorState={inputs.description}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="btn_submit">
                <button className="btn_style">اضافه</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddNewCourse;
