import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCourse, courseImg } from "../store/CourseSlice";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import { addNewCustomer } from "../store/customerServicesSlice";
import { addUser } from "../store/usersSlice";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { getMarkaterById, getTeamLeaders, markaterEdit } from "../store/marketersSlice";
import Select from "react-select";

function EditMarkater() {
  const userRole = Cookies.get("user_role");
  const params = useParams();
  const marketerId = params.marketerId;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const markaterInfo = useSelector((state) => state.marketersSlice.markaterInfo)
  const teamLeaders = useSelector((state) => state.marketersSlice.teamLeaders);
  const [loading, setLoading] = useState(true);
  const [selectVal, setSelectVal] = useState(true);
  const [optionselect, setOptionSelect] = useState();
  const [inputs, setInputs] = useState({
    teamLeaderId: ""
  });


  useEffect(() => {
    dispatch(getMarkaterById(marketerId)).unwrap().then((res) => {
      // console.log(res)
      setInputs({
        teamLeaderId: res.teamLeaderId,
      })

      if(res.teamLeaderId != null){
        dispatch(getMarkaterById(res.teamLeaderId)).unwrap().then((data) => {
          console.log(data)
          setSelectVal({
            value: data.id,
            label: data.name
          })
  
        }).catch((err) => {
          console.error(err)
          setLoading(false);
        });
  
      }

      setLoading(false);
    }).catch((err) => {
      console.error(err)
      setLoading(false);
    })

    dispatch(getTeamLeaders()).unwrap().then((data) => {
      const newTeamLeaders = data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setOptionSelect(newTeamLeaders);
      // setLoading(false);

    }).catch((err) => {
      console.error(err)
      setLoading(false);
    })

  }, [dispatch])

  // const handelInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  // };


  const onSelectChange = (value, action) => {
    const teamleaderid = value.value;
    setSelectVal({
      value: value.value,
      label: value.label,
    })
    setInputs((prevInputs) => ({ ...prevInputs, teamLeaderId: teamleaderid }));
  };

  const saveData = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(inputs);
    dispatch(markaterEdit({ markaterId: marketerId, markaterData: inputs })).unwrap().then((res) => {
      toast.success('تم الاضافه بنجاح');
      setTimeout(() => {
        setLoading(false);
        toast.success('تم التعديل بنجاح')
        if (userRole == 0) {
          navigate("/dashboard/markaters")
        }
        if (userRole == 1) {
          navigate("/supervisor/markaters")
        }
      }, 1000)
    }).catch((err) => {
      toast.error(err.response.data.title);
      setLoading(false);
    });
  };

  return (
    <>
      {loading ? <Loading open={true} /> : null}
      <div className="form_style">
        <div className="form_header">
          <h5>إضافة مستخدم جديد</h5>
          <p>يرجي ادخال البيانات التالية</p>
        </div>
        <form action="" onSubmit={saveData}>
          <div className="row">



            <div className="col-sm-12 col-md-6 col-lg-6">
              <label htmlFor="">مدير الفريق</label>
              <div className="form-group">
                <Select
                  closeMenuOnSelect={true}
                  name="colors"
                  value={selectVal}
                  options={optionselect}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  id="SkillsTags"
                  onChange={onSelectChange}
                // onInputChange={handelFilterChange}
                // hideSelectedOptions={false}
                />
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="btn_submit">
                <button className="btn_style">اضافه</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default EditMarkater;
