import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./routes/Login";

function App() {
  return (
    <div className="App">
      <ToastContainer theme="colored" />
      <Login />
    </div>
  );
}

export default App;
