import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addCourse, courseImg } from "../store/CourseSlice";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import { addNewCustomer } from "../store/customerServicesSlice";
import { addUser } from "../store/usersSlice";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function AddNewUser() {
  const userRole = Cookies.get("user_role");
  const dispatch = useDispatch();
  const navigate  =useNavigate();
  const [loading, setLoading] = useState();
  const [inputs, setInputs] = useState({
    name: "",
    userName: "",
    password: "",
    role: "",
  });

  const handelInputChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const handelSelectChange = (e) => {
    const { name, value } = e.target;
    if (value == 0) {
      setInputs((prevInputs) => ({ ...prevInputs, name: "admin" }));
      setInputs((prevInputs) => ({ ...prevInputs, role: value }));
    }
    if (value == 1) {
      setInputs((prevInputs) => ({ ...prevInputs, name: "supervisor" }));
      setInputs((prevInputs) => ({ ...prevInputs, role: value }));
    }
  };

  const saveData = (e) => {
    e.preventDefault();
    setLoading(true);
    inputs.role = parseInt(inputs.role);
    console.log(inputs);
    dispatch(addUser(inputs)).unwrap().then((res)=>{
      toast.success('تم الاضافه بنجاح');
      setTimeout(()=>{
        setLoading(false);
        if(userRole == 0){
          navigate("/dashboard/all-users")
        }
        if(userRole == 1){
          navigate("/supervisor/all-users")
        }
      }, 1000)
    }).catch((err) => {
      toast.error(err.response.data.title);
      setLoading(false);
    });
  };

  return (
    <>
      {loading ? <Loading open={true} /> : null}
      <div className="form_style">
        <div className="form_header">
          <h5>إضافة مستخدم جديد</h5>
          <p>يرجي ادخال البيانات التالية</p>
        </div>
        <form action="" onSubmit={saveData}>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <label htmlFor="">اسم المستخدم</label>
              <div className="form-group">
                <input
                  type="text"
                  name="userName"
                  onChange={handelInputChange}
                  value={inputs.userName}
                  id=""
                  className="form-control"
                  placeholder="اسم المستخدم"
                />
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6">
              <label htmlFor="">الرقم السري</label>
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  onChange={handelInputChange}
                  value={inputs.password}
                  id=""
                  className="form-control"
                  placeholder="الرقم السري"
                />
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6">
              <label htmlFor="">الصلاحية</label>
              <div className="form-group">
                <select
                  id=""
                  onChange={handelSelectChange}
                  className="form-control"
                >
                  <option value="" selected disabled>
                    اختر
                  </option>
                  <option value={0} name="admin">
                    ادمن
                  </option>
                  <option value={1} name="supervisor">
                    مشرف
                  </option>
                </select>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="btn_submit">
                <button className="btn_style">اضافه</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddNewUser;
