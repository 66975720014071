import React from 'react'

function StaticsCard({course}) {
  return (
    <div className="statics_card">
      <h5>{course.courseName}</h5>
      <p className="number">{course.unCalledCount}</p>
      {/* <CircularChart statics={statics.districts} label="احياء" /> */}
    </div>
  )
}

export default StaticsCard