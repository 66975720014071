import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addCourse, courseImg } from "../store/CourseSlice";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import { addNewCustomer } from "../store/customerServicesSlice";

function AddNewCustomer() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [inputs, setInputs] = useState({
    name: "",
  });

  const handelInputChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };


  const saveData = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(addNewCustomer(inputs)).unwrap().then((res)=>{
      toast.success('تم الاضافه بنجاح');
      setLoading(false);
    }).catch((err) => {
      toast.error(err.response.data.title);
      setLoading(false);
    });
  };

  return (
    <>
      {loading ? <Loading open={true} /> : null}
      <div className="form_style">
        <div className="form_header">
          <h5>إضافة شخص جديد</h5>
          <p>يرجي ادخال البيانات التالية</p>
        </div>
        <form action="" onSubmit={saveData}>
          <div className="row">  
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  onChange={handelInputChange}
                  value={inputs.name}
                  id=""
                  className="form-control"
                  placeholder="الاسم"
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="btn_submit">
                <button className="btn_style">اضافه</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddNewCustomer;
