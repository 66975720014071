import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://backend.egy-courses.com/api/";

export const getmarkaters = createAsyncThunk(
  "markaters/get-markaters",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${endpoint}Markters`);
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getmarkatersForTeamLeader = createAsyncThunk(
  "markaters/get-markaters-teamleader",
  async (markaterId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(
        `${endpoint}Markters?teamLeaderId=${markaterId}`
      );
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const formRequestmarkaterId = createAsyncThunk(
  "markaters/formrequest-markaterId",
  async ({ pageNumber, markaterId }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(
        `${endpoint}FormRequests?markterId=${markaterId}&needPage=true&pageNumber=${pageNumber}&pageSize=100`
      );
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const emptyMarketerReq = createAsyncThunk(
  "markaters/empty-markater-req",
  async (customerId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.post(
        `${endpoint}Statistics/RemoveMarktersFromRequests/${customerId}`
      );
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteMarkater = createAsyncThunk(
  "markaters/delete-markater",
  async (markaterId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.delete(`${endpoint}Markters/${markaterId}`);
      const data = await res.data;
      console.log(data);
      return markaterId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMarkaterById = createAsyncThunk(
  "markaters/get-markater-by-id",
  async (markaterId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${endpoint}Markters/${markaterId}`);
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTeamLeaders = createAsyncThunk(
  "markaters/get-team-leaders",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${endpoint}Markters?wantTeamLeader=true`);
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const markaterEdit = createAsyncThunk(
  "markaters/markaterEdit",
  async ({ markaterId, markaterData }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.put(
        `${endpoint}Markters/${markaterId}`,
        markaterData
      );
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const markaterLogin = createAsyncThunk(
  "markaters/login",
  async (markaterdata, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.post(`${endpoint}Markters/login`, markaterdata);
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const convertMarkterToTeamLeader = createAsyncThunk(
  "markaters/convertMarkerToTeamLeader",
  async (markaterId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.put(
        `${endpoint}Markters/ChangeTeamLeader?id=${markaterId}`
      );
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const markaterRequests = createAsyncThunk(
  "markaters/markaterrequests",
  async ({ markateruserId, page }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(
        `${endpoint}FormRequests?markterId=${markateruserId}&pageNumber=${page}&needPage=true&pageSize=100`
      );
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const markaterInfo = createAsyncThunk(
  "markaters/markaterInfo-data",
  async (markaterId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${endpoint}Markters/${markaterId}`);
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const marketersSlice = createSlice({
  name: "markaters",
  initialState: {
    isLoading: true,
    markaters: [],
    requestsmarketereId: [],
    markaterInfo: {},
    teamLeaders: [],
    marketerteamleader: [],
    markaterRequestsData: [],
    allMarkaterInfo: {},
  },
  reducers: {},
  extraReducers: {
    [getmarkaters.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getmarkaters.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.markaters = action.payload;
    },
    [getmarkaters.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [formRequestmarkaterId.pending]: (state, action) => {
      state.isLoading = true;
    },
    [formRequestmarkaterId.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.requestsmarketereId = action.payload.items;
    },
    [formRequestmarkaterId.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // emptyMarketerReq
    [emptyMarketerReq.pending]: (state, action) => {
      state.isLoading = true;
    },
    [emptyMarketerReq.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [emptyMarketerReq.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // deleteMarkater
    [deleteMarkater.pending]: (state, action) => {
      state.isLoading = true;
    },
    [deleteMarkater.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.markaters = state.markaters.filter(
        (markater) => markater.id !== action.payload
      );
    },
    [deleteMarkater.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getMarkaterById
    [getMarkaterById.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getMarkaterById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.markaterInfo = action.payload;
    },
    [getMarkaterById.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getTeamLeaders
    [getTeamLeaders.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getTeamLeaders.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.teamLeaders = action.payload;
    },
    [getTeamLeaders.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // markaterEdit
    [markaterEdit.pending]: (state, action) => {
      state.isLoading = true;
    },
    [markaterEdit.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [markaterEdit.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getmarkatersForTeamLeader
    [getmarkatersForTeamLeader.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getmarkatersForTeamLeader.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.marketerteamleader = action.payload;
    },
    [getmarkatersForTeamLeader.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // markaterLogin
    [markaterLogin.pending]: (state, action) => {
      state.isLoading = true;
    },
    [markaterLogin.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [markaterLogin.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // convertMarkterToTeamLeader
    [convertMarkterToTeamLeader.pending]: (state, action) => {
      state.isLoading = true;
    },
    [convertMarkterToTeamLeader.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [convertMarkterToTeamLeader.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // markaterRequests
    [markaterRequests.pending]: (state, action) => {
      state.isLoading = true;
    },
    [markaterRequests.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.markaterRequestsData = action.payload.items;
    },
    [markaterRequests.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // markaterInfo
    [markaterInfo.pending]: (state, action) => {
      state.isLoading = true;
    },
    [markaterInfo.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allMarkaterInfo = action.payload;
    },
    [markaterInfo.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export default marketersSlice.reducer;
