import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import DataGridDemo from "../components/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { customerServices } from "../store/customerServicesSlice";
import { formRequestscustomerServiceId } from "../store/requestsSlices";
import { toast } from "react-toastify";
import { emptyCustomeReq } from "../store/customerServicesSlice";
import {
  emptyMarketerReq,
  formRequestmarkaterId,
} from "../store/marketersSlice";
import Cookies from "js-cookie";
import { Pagination } from "@mui/material";

function MarketerRequests() {
  const selectionRows = false;
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const markaterId = params.id;
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [loading, setLoading] = useState(true);
  const userRole = Cookies.get("user_role");
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = useState();
  const markaterrequests = useSelector(
    (state) => state.marketersSlice.requestsmarketereId
  );

  var modifiedArray = markaterrequests.map((item) => ({
    ...item, // Keep all the existing properties
    courseName: item?.courseModel?.name, // Add courseName property
    markterName: item?.markterModel?.name, // Add markterName property
    orderStatusName: item?.orderStatusModel?.name,
    orderDate: item.timeCreated != null ? item?.timeCreated.split("T")[0] : "",
    orderTime: item.timeCreated != null ? item?.timeCreated.split("T")[1] : "",
    iscalled2: item.isCalled ? "تم الاصال" : "لم يتم الاتصال",
  }));

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      formRequestmarkaterId({ pageNumber: page, markaterId: markaterId })
    )
      .unwrap()
      .then((res) => {
        setLoading(false);
        setPageCount(res.totalPages);
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  }, [dispatch, page]);

  const handleRowSelection = (selectionModel) => {
    setRowSelectionModel(selectionModel);
    // setInputs((prevInputs) => ({ ...prevInputs, district_id: selectionModel }));
    // console.log("Selected Rows:", selectionModel);

    // const selectedRowData = selectionModel.map((selectedId) =>
    //   rows.find((row) => row.id === selectedId)
    // );
    // console.log("Selected Row Data:", selectedRowData);
  };

  const emptyRequests = () => {
    setLoading(true);
    dispatch(emptyMarketerReq(markaterId))
      .unwrap()
      .then((data) => {
        toast.success("تم التفريغ بنجاح");
        if(userRole == 0){
          setTimeout(() => {
            navigate("/dashboard/markaters");
            setLoading(false);
          }, 1000);
        }else if(userRole==4){
          setTimeout(() => {
            navigate("/teamleader/dashboard/team");
            setLoading(false);
          }, 1000);
        }

      });
  };

  const coulmns = [
    {
      field: "orderDate",
      headerName: "التاريخ",
      width: 150,
    },
    {
      field: "orderTime",
      headerName: "الوقت",
      width: 150,
    },
    { field: "arName", headerName: "الاسم", width: 200 },

    { field: "phone1", headerName: "الهاتف الاول", width: 200 },
    { field: "phone2", headerName: "الهاتف الثاني", width: 200 },
    {
      field: "courseName",
      headerName: "الكورس",
      width: 150,
    },
    {
      field: "iscalled2",
      headerName: "حاله الاتصال",
      width: 150,
    },
    {
      field: "orderStatusName",
      headerName: "حاله الحجز",
      width: 150,
    },
    {
      field: "markterName",
      headerName: "المسوق",
      width: 150,
    },
    {
      field: "customerServiceName",
      headerName: "خدمه العملاء",
      width: 150,
    },

    { field: "education", headerName: "التعليم", width: 200 },
    { field: "country", headerName: "الدوله", width: 200 },
    { field: "city", headerName: "المدينه", width: 200 },
  ];

  return (
    <>
      {loading ? (
        <Loading open={true} />
      ) : (
        <div className="manage_employee">
          <div className="table_show table_style">
            <h5>الحجوزات</h5>

            {userRole == 0 ? (
              markaterrequests.length == 0 ? (
                ""
              ) : (
                <div className="empty_courses">
                  <button className="btn_style" onClick={emptyRequests}>
                    تفريغ الحجوزات
                  </button>
                </div>
              )
            ) : null}


            <DataGridDemo
              selectionRows={selectionRows}
              coulmns={coulmns}
              rows={modifiedArray}
              handleRowSelection={handleRowSelection}
            />
            <Pagination
              count={pageCount}
              page={page}
              onChange={handleChange}
              color="primary"
              className="paginaitonMod"
            />
          </div>
        </div>
      )}
    </>
  );
}

export default MarketerRequests;
