import React, { useEffect, useState } from "react";
import DataGridDemo from "../components/DataTable";
import Loading from "../components/Loading";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { coursesGetAll } from "../store/CourseSlice";
import { toast } from "react-toastify";
import { Delete } from "@mui/icons-material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { formRequests } from "../store/requestsSlices";
import {
  deleteRequest,
  emptyCompletedRigisterd,
  requestscomplete,
  requestscompleteFilter,
} from "../store/requestscompleteSlice";
import * as XLSX from "xlsx";
import Cookies from "js-cookie";
import Pagination from "@mui/material/Pagination";

function CourseComplete() {
  const selectionRows = false;
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const allCompetedCourses = useSelector(
    (state) => state.requestscompleteSlice.allcompleterequests
  );
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const userRole = Cookies.get("user_role");
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = useState();
  const adminUser = userRole == 0 ? true : false;
  const [dates, setDates] = useState({
    startDate: "",
    endDate: "",
  });
  const navigate = useNavigate();
  const handleChange = (event, value) => {
    setPage(value);
  };

  const handledelete = (reqId) => {
    confirmAlert({
      title: "تاكيد الحذف",
      message: "هل انت متاكد من ذلك",
      buttons: [
        {
          label: "تاكيد",
          onClick: () => {
            setLoading(true);
            dispatch(deleteRequest(reqId))
              .unwrap()
              .then((res) => {
                toast.success("تم المسح بنجاح");
                setLoading(false);
                navigate("/dashboard/course-rigister");
              })
              .catch((err) => {
                toast.error(err.response.data);
                setLoading(false);
              });
          },
        },
        {
          label: "الغاء",
        },
      ],
    });
  };

  var modifiedArray = allCompetedCourses.map((item) => ({
    ...item, // Keep all the existing properties
    courseName: item?.courseModel?.name, // Add courseName property
    markterName: item?.markterModel?.name, // Add markterName property
    orderStatusName: item?.orderStatusModel?.name,
    orderDate: item.timeCreated != null ? item?.timeCreated.split("T")[0] : "",
    orderTime: item.timeCreated != null ? item?.timeCreated.split("T")[1] : "",
    orderChangeDate:
      item.timeStatusChanged != null ? item?.timeCreated.split("T")[0] : "",
    orderChangeTime:
      item.timeStatusChanged != null ? item?.timeCreated.split("T")[1] : "",
    iscalled2: item.isCalled ? "تم الاصال" : "لم يتم الاتصال",
  }));

  const coulmns = [
    {
      field: "orderDate",
      headerName: "التاريخ",
      width: 150,
    },
    {
      field: "orderTime",
      headerName: "الوقت",
      width: 150,
    },
    { field: "arName", headerName: "الاسم", width: 150 },
    { field: "phone1", headerName: "الهاتف الاول", width: 150 },
    { field: "phone2", headerName: "الهاتف الثاني", width: 150 },
    {
      field: "courseName",
      headerName: "الكورس",
      width: 150,
    },
    {
      field: "iscalled2",
      headerName: "حاله الاتصال",
      width: 150,
      // renderCell: (params) => (
      //   <div className="">
      //     <span className="">
      //       {params.row.isCalled ? (
      //         <span className="callDone">تم الاتصال</span>
      //       ) : (
      //         <span className="callNotDone">لم يتم الاتصال</span>
      //       )}
      //     </span>
      //   </div>
      // ),
    },
    {
      field: "orderStatusName",
      headerName: "حاله الحجز",
      width: 150,
    },
    {
      field: "orderChangeDate",
      headerName: "تاريخ تأكيد الحجز",
      width: 150,
    },
    {
      field: "orderChangeTime",
      headerName: "وقت تأكيد الحجز",
      width: 150,
    },
    {
      field: "markterName",
      headerName: "المسوق",
      width: 150,
    },
    { field: "education", headerName: "التعليم", width: 150 },
    { field: "country", headerName: "الدوله", width: 150 },
    { field: "city", headerName: "المدينه", width: 150 },
  ];

  if (userRole == 0) {
    coulmns.push({
      field: "actions",
      headerName: "اختيارات",
      width: 150,
      renderCell: (params) => (
        <div className="tableoptions">
          <span className="deleteicon">
            <Delete
              onClick={() => handledelete(params.row.id)}
              style={{ cursor: "pointer" }}
            />
          </span>
        </div>
      ),
    });
  }

  const handleRowSelection = (selectionModel) => {
    setRowSelectionModel(selectionModel);
    // setInputs((prevInputs) => ({ ...prevInputs, district_id: selectionModel }));
    // console.log("Selected Rows:", selectionModel);

    // const selectedRowData = selectionModel.map((selectedId) =>
    //   rows.find((row) => row.id === selectedId)
    // );
    // console.log("Selected Row Data:", selectedRowData);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      requestscomplete({
        startDate: dates.startDate,
        endDate: dates.endDate,
        pageNumber: page,
      })
    )
      .unwrap()
      .then((res) => {
        // toast.success('')
        setLoading(false);
        setPageCount(res.totalPages);
        console.log(res);
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  }, [dispatch, page]);

  const handelFilterChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setDates((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const filterByDate = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      requestscomplete({
        startDate: dates.startDate,
        endDate: dates.endDate,
        pageNumber: 1,
      })
    )
      .unwrap()
      .then((res) => {
        // toast.success('')
        setLoading(false);
        setPageCount(res.totalPages);
        setPage(1);
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };

  const emotyCompleteCourses = () => {
    setLoading(true);
    dispatch(
      emptyCompletedRigisterd({
        startDate: dates.startDate,
        endDate: dates.endDate,
      })
    )
      .unwrap()
      .then((res) => {
        toast("تم التفريغ بنجاح");
        setTimeout(() => {
          window.location.reload();
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.message);
      });
  };

  const downloadData = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await dispatch(
        requestscompleteFilter({
          startDate: dates.startDate,
          endDate: dates.endDate,
          pageNumber: 1,
        })
      )
        .unwrap()
        .then((res) => {
          setLoading(true);
          var modifiedArray = res.map((item) => ({
            orderDate:
              item.timeCreated != null ? item?.timeCreated.split("T")[0] : "",
            orderTime:
              item.timeCreated != null ? item?.timeCreated.split("T")[1] : "",
            name: item?.arName,
            firstphone: item?.phone1,
            secondphone: item?.phone2,
            courseName: item?.courseModel?.name, // Add courseName property
            iscalled: "تم الاتصال",
            orderStatus: item?.orderStatusModel?.name,
            markterName: item?.markterModel?.name, // Add markterName property
            customerServiceModel: item?.customerServiceModel?.name,
            ...item, // Keep all the existing properties
          }));

          const filteredRes = modifiedArray.map((item) => {
            // Destructure the object and remove the properties you don't need
            const {
              courseId,
              enName,
              arName,
              phone1,
              phone2,
              customerServiceId,
              orderStatusId,
              markterId,
              courseModel,
              markterModel,
              orderStatusModel,
              customerServiceModel,
              isCalled,
              id,
              timeCreated,
              ...rest
            } = item;
            return rest;
          });

          downloadExcelFile(filteredRes, "filtered_data.xlsx"); // Function to generate Excel data

          // Trigger download
          // downloadExcel(excelData, "data.xlsx");
          setLoading(false);
          // window.location.reload();
        })
        .catch((err) => {
          toast.error(err.response.data);
        });
    } catch (err) {
      toast.error(err.response.data);
      setLoading(false);
    }
  };

  const downloadExcelFile = (data, fileName) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    XLSX.writeFile(wb, fileName);
  };

  return (
    <>
      {loading ? <Loading open={true} /> : null}
      <div className="manage_employee">
        <div className="add_button">
          {userRole == 0 ? (
            <Link to="/dashboard/add-new-course">اضافة كورس</Link>
          ) : null}
        </div>
        <div className="table_show table_style">
          <h5>تاكيدات الحجز</h5>

          <div
            className="filter custom_filter"
            style={{ marginBottom: "50px" }}
          >
            <form action="" onSubmit={filterByDate}>
              <div className="form-group">
                <label htmlFor="">تاريخ البدايه </label>
                <input
                  type="date"
                  className="form-control"
                  onChange={handelFilterChange}
                  name="startDate"
                />
              </div>
              <div className="form-group">
                <label htmlFor="">تاريخ النهايه </label>
                <input
                  type="date"
                  className="form-control"
                  onChange={handelFilterChange}
                  name="endDate"
                />
              </div>

              <div className="form-group">
                <label htmlFor=""> بدايه تاريخ حاله الحجز</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={handelFilterChange}
                  name="startDate"
                />
              </div>

              <div className="form-group">
                <label htmlFor=""> نهايه تاريخ حاله الحجز</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={handelFilterChange}
                  name="endDate"
                />
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn_style">
                  تصفيه
                </button>
              </div>
            </form>
          </div>

          {userRole == 0 ? (
            <div className="empty_complete d-flex align-items-center justify-content-start">
              <button className="btn btn_style" onClick={emotyCompleteCourses}>
                تفريغ الحجوزات
              </button>
              <button
                className="btn btn_style"
                style={{ marginRight: "20px" }}
                onClick={downloadData}
              >
                تحميل البيانات
              </button>
            </div>
          ) : null}

          <DataGridDemo
            selectionRows={selectionRows}
            coulmns={coulmns}
            rows={modifiedArray}
            handleRowSelection={handleRowSelection}
            adminUser={adminUser}
          />

          <Pagination
            showFirstButton
            showLastButton
            count={pageCount}
            page={page}
            onChange={handleChange}
            color="primary"
            className="paginaitonMod"
          />
        </div>
      </div>
    </>
  );
}

export default CourseComplete;
