import React, { useEffect, useState } from "react";
import DataGridDemo from "../components/DataTable";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { coursesGetAll, makeCoursecall } from "../store/CourseSlice";
import { toast } from "react-toastify";
import { Delete } from "@mui/icons-material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
  formRequests,
  requestHistoryFilter,
  requestHistoryFilterToDownload,
  requestStatus,
} from "../store/requestsSlices";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Cookies from "js-cookie";
import Pagination from "@mui/material/Pagination";
import * as XLSX from "xlsx";


function RequestsHistory() {
  // const selectionRows = true;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const coursesrigister = useSelector(
    (state) => state.requestsSlices.allrequests
  );
  const orderstatus = useSelector((state) => state.requestsSlices.orderStatus);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const userRole = Cookies.get("user_role");
  const selectionRows = userRole == 0 ? true : false;
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = useState();
  const adminUser = userRole == 0 ? true : false;
  const handleChange = (event, value) => {
    setPage(value);
  };

  const [dates, setDates] = useState({
    startDate: "",
    endDate: "",
    statusId: "",
    startChangeDate: "",
    endChangeDate: "",
  });

  var modifiedArray = coursesrigister.map((item) => ({
    ...item, // Keep all the existing properties
    courseName: item?.courseModel?.name, // Add courseName property
    markterName: item?.markterModel?.name, // Add markterName property
    orderStatusName: item?.orderStatusModel?.name,
    orderDate: item.timeCreated != null ? item?.timeCreated.split("T")[0] : "",
    orderTime: item.timeCreated != null ? item?.timeCreated.split("T")[1] : "",
    iscalled2: item.isCalled ? "تم الاصال" : "لم يتم الاتصال",
  }));

  const handledelete = (id) => {
    confirmAlert({
      title: "تاكيد الحذف",
      message: "هل انت متاكد من ذلك",
      buttons: [
        {
          label: "تاكيد",
          // onClick: () =>
          //   dispatch(deleteArea(id))
          //     .unwrap()
          //     .then((data) => {
          //       toast.success("تم المسح بنجاح");
          //     })
          //     .catch((error) => {
          //       toast.error(error.message);
          //     }),
        },
        {
          label: "الغاء",
        },
      ],
    });
  };

  const coulmns = [
    {
      field: "orderDate",
      headerName: "التاريخ",
      width: 150,
    },
    {
      field: "orderTime",
      headerName: "الوقت",
      width: 150,
    },
    { field: "arName", headerName: "الاسم", width: 200 },

    { field: "phone1", headerName: "الهاتف الاول", width: 200 },
    { field: "phone2", headerName: "الهاتف الثاني", width: 200 },
    {
      field: "iscalled2",
      headerName: "حاله الاتصال",
      width: 150,
    },
    {
      field: "orderStatusName",
      headerName: "حاله الحجز",
      width: 150,
    },
    {
      field: "courseName",
      headerName: "الكورس",
      width: 150,
    },
    {
      field: "markterName",
      headerName: "المسوق",
      width: 150,
    },
    {
      field: "customerServiceName",
      headerName: "خدمه العملاء",
      width: 150,
    },

    {
      field: "orderDate",
      headerName: "التاريخ",
      width: 150,
    },

    { field: "education", headerName: "التعليم", width: 200 },
    { field: "country", headerName: "الدوله", width: 200 },
    { field: "city", headerName: "المدينه", width: 200 },
  ];

  const handleRowSelection = (selectionModel) => {
    setRowSelectionModel(selectionModel);
    console.log(selectionModel);
    // setInputs((prevInputs) => ({ ...prevInputs, district_id: selectionModel }));
    // console.log("Selected Rows:", selectionModel);

    // const selectedRowData = selectionModel.map((selectedId) =>
    //   coursesrigister.find((row) => row.id === selectedId)
    // );
  };

  const makeItCall = () => {
    console.log(rowSelectionModel);
    dispatch(makeCoursecall(rowSelectionModel))
      .unwrap()
      .then((res) => {
        toast.success("تم الاتصال بنجاح");
        window.location.reload();
      })
      .catch((err) => {
        toast.error("هناك مشكله ما");
      });
  };

  const filterByDateHistoy = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(dates);
    dispatch(
      requestHistoryFilter({
        startDate: dates.startDate,
        endDate: dates.endDate,
        pageNumber: 1,
        statusId: dates.statusId,
      })
    )
      .unwrap()
      .then((res) => {
        // toast.success('')
        setLoading(false);
        setPageCount(res.totalPages);
        setPage(1);
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };

  const handelFilterChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setDates((prevInputs) => ({ ...prevInputs, [name]: value }));
  };


  const downloadData = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await dispatch(
        requestHistoryFilterToDownload({
          startDate: dates.startDate,
          endDate: dates.endDate,
          pageNumber: 1,
          statusId: dates.statusId,
        })
      ).unwrap()
        .then((res) => {
          setLoading(true);
          var modifiedArray = res.map((item) => ({
            orderDate:
              item.timeCreated != null ? item?.timeCreated.split("T")[0] : "",
            orderTime:
              item.timeCreated != null ? item?.timeCreated.split("T")[1] : "",
            name: item?.arName,
            firstphone: item?.phone1,
            secondphone: item?.phone2,
            courseName: item?.courseModel?.name, // Add courseName property
            iscalled: item?.isCalled,
            orderStatus: item?.orderStatusModel?.name,
            markterName: item?.markterModel?.name, // Add markterName property
            customerServiceModel: item?.customerServiceModel?.name,
            ...item, // Keep all the existing properties
          }));

          const filteredRes = modifiedArray.map((item) => {
            // Destructure the object and remove the properties you don't need
            const {
              courseId,
              enName,
              arName,
              phone1,
              phone2,
              customerServiceId,
              orderStatusId,
              markterId,
              courseModel,
              markterModel,
              orderStatusModel,
              customerServiceModel,
              isCalled,
              id,
              timeCreated,
              ...rest
            } = item;
            return rest;
          });

          downloadExcelFile(filteredRes, "filtered_data.xlsx"); // Function to generate Excel data

          // Trigger download
          // downloadExcel(excelData, "data.xlsx");
          setLoading(false);
          // window.location.reload();
        })

    } catch (err) {
      toast.error(err.response.data);
      setLoading(false);
    }
  };

  const downloadExcelFile = (data, fileName) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    XLSX.writeFile(wb, fileName);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(requestStatus())
      .unwrap()
      .then((res) => {
        // toast.success('')
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setLoading(false);
      });

    dispatch(
      formRequests({
        markterId: "",
        courseId: "",
        startDate: dates.startDate,
        endDate: dates.endDate,
        statusId: dates.statusId,
        pageNumber: page,
        pageNumber: page,
        courseIds: [],
        isCalled: "",
        startChangeDate: dates.startChangeDate,
        endChangeDate: dates.endChangeDate,
      })
    )
      .unwrap()
      .then((res) => {
        // toast.success('')
        setLoading(false);
        setPageCount(res.totalPages);
        console.log(res)
      })
      .catch((err) => {
        toast.error(err.response.data);
        setLoading(false);
      });
  }, [dispatch, page]);

  return (
    <>
      {loading ? <Loading open={true} /> : null}
      <div className="manage_employee">
        <div className="add_button">
          {userRole == 0 ? (
            <Link to="/dashboard/add-new-course">اضافة كورس</Link>
          ) : null}
        </div>
        <div className="table_show table_style">
          <h5>حجوزات الكورسات</h5>

          <div className="filter custom_filter" style={{ marginBottom: "50px" }}>
            <form action="" onSubmit={filterByDateHistoy}>
              <div className="form-group">
                <label htmlFor="">تاريخ البدايه </label>
                <input
                  type="date"
                  className="form-control"
                  onChange={handelFilterChange}
                  name="startDate"
                />
              </div>
              <div className="form-group">
                <label htmlFor="">تاريخ النهايه </label>
                <input
                  type="date"
                  className="form-control"
                  onChange={handelFilterChange}
                  name="endDate"
                />
              </div>

             
              <div className="form-group">
                <label htmlFor=""> بدايه تاريخ حاله الحجز</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={handelFilterChange}
                  name="startDate"
                />
              </div>

              <div className="form-group">
                <label htmlFor=""> نهايه تاريخ حاله الحجز</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={handelFilterChange}
                  name="endDate"
                />
              </div>

              <div className="form-group">
                {/* <label htmlFor="">الحاله</label> */}
                <select name="statusId" id="" onChange={handelFilterChange} className="form-control">
                  <option value={dates.statusId} selected disabled>الحاله</option>
                  {orderstatus.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <button type="submit" className="btn btn_style">
                  تصفيه
                </button>
              </div>
            </form>
          </div>



          {/* <div className="filter" style={{ marginBottom: "50px" }}>
            <form action="" onSubmit={filterByDateHistoy}>
            

            </form>
          </div> */}

          {/* {userRole == 0 ? (
            <>
              <button className="make_it_called" onClick={makeItCall}>
                <span>حالة الاتصال</span>
              </button>
            </>
          ) : null} */}

          {userRole == 0 ? (
            <div className="empty_complete d-flex align-items-center justify-content-start">
              <button
                className="btn btn_style"
                style={{ marginRight: "20px" }}
                onClick={downloadData}
              >
                تحميل البيانات
              </button>
            </div>
          ) : null}

          <DataGridDemo
            selectionRows={selectionRows}
            coulmns={coulmns}
            rows={modifiedArray}
            handleRowSelection={handleRowSelection}
            adminUser={adminUser}
          />
          <Pagination
            showFirstButton
            showLastButton
            count={pageCount}
            page={page}
            onChange={handleChange}
            color="primary"
            className="paginaitonMod"
          />
        </div>
      </div>
    </>
  );
}

export default RequestsHistory;
