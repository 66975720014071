import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import TextField from "@mui/material/TextField";
import { Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import Loading from "../../components/Loading";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import StaticsCard from "../../components/StaticsCard";
import {
  getMarkaterStatics,
  getMarkaterStaticsall,
} from "../../store/statiscsSlice";
import Cookies from "js-cookie";
import StaticsCardTeamLead from "../../components/StaticsCardTeamLead";

function TeamLeaderStatics() {
  const [loading, setLoading] = useState(true);
  const userId = Cookies.get("markter_id");
  console.log(userId);
  const markterStatistics = useSelector(
    (state) => state.statiscSlice.markterStatistics
  );
  const markterStatisticsall = useSelector(
    (state) => state.statiscSlice.markterStatisticsall
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMarkaterStatics(userId))
      .unwrap()
      .then((data) => {
        setLoading(false);
      });

    dispatch(getMarkaterStaticsall(userId))
      .unwrap()
      .then((data) => {
        setLoading(false);
      });
  }, [dispatch]);

  return (
    <>
      {loading ? <Loading open={true} /> : null}
      <div className="statics_wrapper">
        <div className="statics_cards">
          <Container maxWidth="xxl">
            <h5 className="statics_header">الاحصائيات</h5>
            <Grid container spacing={1} className="">
              {markterStatistics?.map((st) => (
                <Grid xs={12} md={12} lg={12} key={st.id}>
                  <StaticsCardTeamLead marktertatistics={st} courseName="لم يتم الاتصال بهم" />
                </Grid>
              ))}
            </Grid>
          </Container>

          <Container maxWidth="xxl">
            <Grid container spacing={1} className="">
              {markterStatisticsall?.map((st) => (
                <Grid xs={12} md={4} key={st.id}>
                  <StaticsCardTeamLead marktertatistics={st} courseName={st.courseName} />
                </Grid>
              ))}
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
}

export default TeamLeaderStatics;
