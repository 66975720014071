import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://backend.egy-courses.com/api/";

export const coursesGetAll = createAsyncThunk(
  "courses/getAll",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${endpoint}Courses`);
      const data = await res.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const courseImg = createAsyncThunk(
  "courses/getcourseImg",
  async (filesData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.post(`${endpoint}Courses/UploadImage`, filesData);
      const data = await res.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addCourse = createAsyncThunk(
  "courses/addCourse",
  async (courseData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.post(`${endpoint}Courses`, courseData);
      const data = await res.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCourseById = createAsyncThunk(
  "courses/getCourseById",
  async (courseId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${endpoint}Courses/${courseId}`);
      const data = await res.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editCourse = createAsyncThunk(
  "courses/editCourse",
  async ({ courseId, courseData }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.put(`${endpoint}Courses/${courseId}`, courseData);
      const data = await res.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCourse = createAsyncThunk(
  "courses/editCourse",
  async (courseId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.delete(`${endpoint}Courses/${courseId}`);
      // const data = await res.data;
      console.log(courseId);
      return courseId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const makeCoursecall = createAsyncThunk(
  "courses/callcaourse",
  async (coursesId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      console.log(coursesId)
      const res = await axios.put(`${endpoint}FormRequests/makeCalled`, coursesId);
      // const data = await res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const courseSlice = createSlice({
  name: "courses",
  initialState: {
    isLoading: true,
    courses: [],
    courseImg: "",
    singleCourse: {},
  },
  reducers: {},
  extraReducers: {
    [coursesGetAll.pending]: (state, action) => {
      state.isLoading = true;
    },
    [coursesGetAll.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.courses = action.payload;
    },
    [coursesGetAll.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // courseImg
    [courseImg.pending]: (state, action) => {
      state.isLoading = true;
    },
    [courseImg.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.courseImg = action.payload;
    },
    [courseImg.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // addCourse
    [addCourse.pending]: (state, action) => {
      state.isLoading = true;
    },
    [addCourse.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [addCourse.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getCourseById
    [getCourseById.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getCourseById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.singleCourse = action.payload;
    },
    [getCourseById.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // editCourse
    [editCourse.pending]: (state, action) => {
      state.isLoading = true;
    },
    [editCourse.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [editCourse.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // deleteCourse
    [deleteCourse.pending]: (state, action) => {
      state.isLoading = true;
    },
    [deleteCourse.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.courses = state.courses.filter((course) => course.id !== action.payload);

    },
    [deleteCourse.rejected]: (state, action) => {
      state.isLoading = false;
    },


    // makeCoursecall
    [makeCoursecall.pending]: (state, action) => {
      state.isLoading = true;
    },
    [makeCoursecall.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [makeCoursecall.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export default courseSlice.reducer;
