import React, { useEffect, useState } from "react";
import DataGridDemo from "../components/DataTable";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { coursesGetAll, makeCoursecall } from "../store/CourseSlice";
import { toast } from "react-toastify";
import { Delete } from "@mui/icons-material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { formRequests, formRequestsToDownload } from "../store/requestsSlices";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Filter from "../components/Filter";
import Cookies from "js-cookie";
import Pagination from "@mui/material/Pagination";
import Select from "react-select";
import FilterToDownload from "../components/FilterToDownload";
// const XLSX = require("xlsx");
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

function CoursesRigister() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const userRole = Cookies.get("user_role");
  const selectionRows = userRole == 0 ? true : false;
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = useState();
  const [requestsIds, setRequestsIds] = useState();
  const adminUser = userRole == 0 ? true : false;
  const [filterInputs, setFilterInputs] = useState({
    markterId: "",
    courseId: "",
    startDate: "",
    endDate: "",
    courseIds: [],
    isCalled: "",
    statusId: "",
  });

  const handleChange = (event, value) => {
    setPage(value);
  };
  const coursesrigister = useSelector(
    (state) => state.requestsSlices.allrequests
  );
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  // const [modifiedArray, setModifiedArray] = useState([]);

  var modifiedArray = coursesrigister.map((item) => ({
    ...item, // Keep all the existing properties
    courseName: item?.courseModel?.name, // Add courseName property
    markterName: item?.markterModel?.name, // Add markterName property
    orderStatusName: item?.orderStatusModel?.name,
    orderDate: item.timeCreated != null ? item?.timeCreated.split("T")[0] : "",
    orderTime: item.timeCreated != null ? item?.timeCreated.split("T")[1] : "",
    iscalled2: item.isCalled ? "تم الاتصال" : "لم يتم الاتصال",
  }));

  const handledelete = (id) => {
    confirmAlert({
      title: "تاكيد الحذف",
      message: "هل انت متاكد من ذلك",
      buttons: [
        {
          label: "تاكيد",
          // onClick: () =>
          //   dispatch(deleteArea(id))
          //     .unwrap()
          //     .then((data) => {
          //       toast.success("تم المسح بنجاح");
          //     })
          //     .catch((error) => {
          //       toast.error(error.message);
          //     }),
        },
        {
          label: "الغاء",
        },
      ],
    });
  };

  const coulmns = [
    {
      field: "orderDate",
      headerName: "التاريخ",
      width: 150,
    },
    {
      field: "orderTime",
      headerName: "الوقت",
      width: 150,
    },

    { field: "arName", headerName: "الاسم", width: 200 },
    { field: "phone1", headerName: "الهاتف الاول", width: 200 },
    { field: "phone2", headerName: "الهاتف الثاني", width: 200 },
    {
      field: "courseName",
      headerName: "الكورس",
      width: 150,
    },
    {
      field: "iscalled2",
      headerName: "حاله الاتصال",
      width: 150,
      // renderCell: (params) => (
      //   <div className="">
      //     <span className="">
      //       {params.row.isCalled ? (
      //         <span className="callDone">تم الاتصال</span>
      //       ) : (
      //         <span className="callNotDone">لم يتم الاتصال</span>
      //       )}
      //     </span>
      //   </div>
      // ),
    },

    {
      field: "orderStatusName",
      headerName: "حاله الحجز",
      width: 150,
    },
    {
      field: "markterName",
      headerName: "المسوق",
      width: 150,
    },
    { field: "education", headerName: "التعليم", width: 200 },
    { field: "country", headerName: "الدوله", width: 200 },
    { field: "city", headerName: "المدينه", width: 200 },
  ];

  const handleRowSelection = (selectionModel) => {
    setRowSelectionModel(selectionModel);
    console.log(selectionModel);
    // setInputs((prevInputs) => ({ ...prevInputs, district_id: selectionModel }));
    // console.log("Selected Rows:", selectionModel);

    // const selectedRowData = selectionModel.map((selectedId) =>
    //   coursesrigister.find((row) => row.id === selectedId)
    // );
  };

  const makeItCall = () => {
    // console.log(rowSelectionModel);
    setLoading(true);
    dispatch(makeCoursecall(rowSelectionModel))
      .unwrap()
      .then((res) => {
        setLoading(false);
        toast.success("تم الاتصال بنجاح");
        window.location.reload();
      })
      .catch((err) => {
        toast.error("هناك مشكله ما");
      });
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      formRequests({
        markterId: filterInputs.markterId,
        courseId: filterInputs.courseId,
        startDate: filterInputs.startDate,
        endDate: filterInputs.endDate,
        pageNumber: page,
        courseIds: filterInputs.courseIds,
        isCalled: filterInputs.isCalled,
        statusId: filterInputs.statusId,
        startChangeDate: "",
        endChangeDate: "",
      })
    )
      .unwrap()
      .then((res) => {
        // toast.success('')
        setLoading(false);
        setPageCount(res.totalPages);
        // var nmodifiedArray = res.map((item) => ({
        //   ...item, // Keep all the existing properties
        //   courseName: item?.courseModel?.name, // Add courseName property
        //   markterName: item?.markterModel?.name, // Add markterName property
        //   orderStatusName: item?.orderStatusModel?.name,
        //   orderDate: item.timeCreated != null ? item?.timeCreated.split("T")[0] : "",
        //   iscalled2: item.isCalled ? "تم الاصال" : "لم يتم الاتصال"
        // }));

        // setModifiedArray(nmodifiedArray)
      })
      .catch((err) => {
        toast.error(err.response.data);
        setLoading(false);
      });
  }, [dispatch, page]);

  const filterData = (e) => {
    setLoading(true);
    e.preventDefault();
    // console.log(filterInputs);
    setPage(1);
    dispatch(
      formRequests({
        markterId: filterInputs.markterId,
        courseId: filterInputs.courseId,
        startDate: filterInputs.startDate,
        endDate: filterInputs.endDate,
        courseIds: filterInputs.courseIds,
        pageNumber: page,
        isCalled: filterInputs.isCalled,
        statusId: filterInputs.statusId,
        startChangeDate: "",
        endChangeDate: "",
      })
    )
      .unwrap()
      .then((res) => {
        setLoading(false);
        setPageCount(res.totalPages);
        console.log(res);
        // var nmodifiedArray = res.map((item) => ({
        //   ...item, // Keep all the existing properties
        //   courseName: item?.courseModel?.name, // Add courseName property
        //   markterName: item?.markterModel?.name, // Add markterName property
        //   orderStatusName: item?.orderStatusModel?.name,
        //   orderDate: item.timeCreated != null ? item?.timeCreated.split("T")[0] : "",
        //   iscalled2: item.isCalled ? "تم الاصال" : "لم يتم الاتصال"
        // }));

        // setModifiedArray(nmodifiedArray)
      })
      .catch((er) => {
        // console.log(er);
        setLoading(false);
      });
  };

  const filterDataToDownload = async (e) => {
    setLoading(true);
    e.preventDefault();
    console.log(filterInputs);
    try {
      const response = await dispatch(
        formRequestsToDownload({
          markterId: filterInputs.markterId,
          courseId: filterInputs.courseId,
          startDate: filterInputs.startDate,
          endDate: filterInputs.endDate,
          courseIds: filterInputs.courseIds,
          isCalled: filterInputs.isCalled,
          pageNumber: page,
        })
      )
        .unwrap()
        .then((res) => {
          console.log(res);
          const allIds = res
            .filter((item) => item.isCalled === false)
            .map((item) => item.id);
          setLoading(true);
          dispatch(makeCoursecall(allIds))
            .unwrap()
            .then((data) => {
              toast.success("تم الاتصال بنجاح");
              setLoading(true);
              var modifiedArray = res.map((item) => ({
                orderDate:
                  item.timeCreated != null
                    ? item?.timeCreated.split("T")[0]
                    : "",
                orderTime:
                  item.timeCreated != null
                    ? item?.timeCreated.split("T")[1]
                    : "",
                name: item?.arName,
                firstphone: item?.phone1,
                secondphone: item?.phone2,
                courseName: item?.courseModel?.name, // Add courseName property
                iscalled: "تم الاتصال",
                orderStatus: item?.orderStatusModel?.name,
                markterName: item?.markterModel?.name, // Add markterName property
                customerServiceModel: item?.customerServiceModel?.name,
                ...item, // Keep all the existing properties
              }));

              const filteredRes = modifiedArray.map((item) => {
                // Destructure the object and remove the properties you don't need
                const {
                  courseId,
                  enName,
                  arName,
                  phone1,
                  phone2,
                  customerServiceId,
                  orderStatusId,
                  markterId,
                  courseModel,
                  markterModel,
                  orderStatusModel,
                  customerServiceModel,
                  isCalled,
                  id,
                  timeCreated,
                  ...rest
                } = item;
                return rest;
              });
              alert("arrive0");

              downloadExcelFile(filteredRes, "filtered_data.xlsx"); // Function to generate Excel data

              // Trigger download
              // downloadExcel(excelData, "data.xlsx");
              setLoading(false);
              window.location.reload();
            })
            .catch((err) => {
              toast.error("هناك مشكله ما");
              setLoading(false);
              // window.location.reload();
            });

          // console.log(filteredRes);
        })
        .catch((er) => {
          console.log(er);
        });
    } catch {
      toast.error("error");
      setLoading(false);
    }
  };

  // Function to generate Excel data from data
  // const generateExcelFromData = (data) => {
  //   const worksheet = XLSX.utils.json_to_sheet(data);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   const excelBuffer = XLSX.write(workbook, {
  //     bookType: "xlsx",
  //     type: "array",
  //   });
  //   // return excelBuffer;
  //   XLSX.writeFile(wb, fileName);
  // };

  /*  const downloadExcelFile = (data, fileName) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    XLSX.writeFile(wb, fileName);
    
  };
*/
  const downloadExcelFile = (data, fileName) => {
    alert("arrive");
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    alert("arrive2");

    // Generate a binary string representation of the workbook
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    alert("arrive3");

    // Create a Blob from the binary string
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    alert("arrive4");

    // Use FileSaver.js to save the file
    saveAs(blob, fileName);
  };
  // Function to initiate download
  // const downloadExcel = (excelData, fileName) => {
  //   const blob = new Blob([excelData], { type: "application/octet-stream" });
  //   const url = window.URL.createObjectURL(blob);
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = fileName;
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  //   window.URL.revokeObjectURL(url);
  //   toast.success("تم تحميل الملف بنجاح وتغيير حالة الاتصال");
  //   setLoading(false);
  // };

  const requestResult = useSelector(
    (state) => state.searchForCourseSlice.requestresults
  );

  return (
    <>
      {loading ? <Loading open={true} /> : null}
      <div className="manage_employee formReq_tabel">
        <div className="add_button">
          {userRole == 0 ? (
            <Link to="/dashboard/add-new-course">اضافة كورس</Link>
          ) : null}
        </div>
        <div className="table_show table_style">
          <h5>حجوزات الكورسات</h5>
          <div className="table_filter">
            <h5 className="head">تصفيه</h5>
            <Filter
              page={page}
              setPage={setPage}
              filterInputs={filterInputs}
              setFilterInputs={setFilterInputs}
              filterData={filterData}
            />
          </div>

          <div className="download_file">
            <FilterToDownload filterDataToDownload={filterDataToDownload} />
          </div>

          {userRole == 0 ? (
            <button className="make_it_called" onClick={makeItCall}>
              <span>حالة الاتصال</span>
            </button>
          ) : null}

          <DataGridDemo
            selectionRows={selectionRows}
            coulmns={coulmns}
            rows={modifiedArray}
            handleRowSelection={handleRowSelection}
            adminUser={adminUser}
          />
          <Pagination
            showFirstButton
            showLastButton
            count={pageCount}
            page={page}
            onChange={handleChange}
            color="primary"
            className="paginaitonMod"
          />
        </div>
      </div>
    </>
  );
}

export default CoursesRigister;
