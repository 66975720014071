import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://backend.egy-courses.com/api/";

export const getAllUsers = createAsyncThunk(
  "users/getAllUsers",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${endpoint}Admains`);
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addUser = createAsyncThunk(
  "users/add-user",
  async (userData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.post(`${endpoint}Admains/register`, userData);
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const deleteUser = createAsyncThunk(
  "users/delete-user",
  async (userID, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.delete(`${endpoint}Admains/${userID}`);
      const data = await res.data;
      return userID;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState: {
    isLoading: true,
    users: [],
    singleUser: {},
  },
  reducers: {},
  extraReducers: {
    [getAllUsers.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAllUsers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.users = action.payload;
    },
    [getAllUsers.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // addUser
    [addUser.pending]: (state, action) => {
      state.isLoading = true;
    },
    [addUser.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [addUser.rejected]: (state, action) => {
      state.isLoading = false;
    },


    // deleteUser
    [deleteUser.pending]: (state, action) => {
      state.isLoading = true;
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.users = state.users.filter((user) => user.id !== action.payload);
    },
    [deleteUser.rejected]: (state, action) => {
      state.isLoading = false;
    },

  },
});

export default usersSlice.reducer;
