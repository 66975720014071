import React from "react";
import { Outlet } from "react-router-dom";
import SideNav from "../components/SideNav";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import App from "../App";
import MobileIcon from "../components/MobileIcon";
import BackDrop from '../components/BackDrop';
import NotAuth from "./NotAuth";

function Dashboard() {
  const userId = Cookies.get("user_id");
  const userName = Cookies.get("user_name");
  const roleName = Cookies.get("role_name");
  const userRole = Cookies.get("user_role");
  console.log(userId, userName);
  if (userId == null || userId == undefined) return <App />;
  if (userName == "" || userName == undefined) return <App />;

  console.log(userRole)
  console.log(roleName)

  if (userRole !== "0") return <NotAuth />;
  if (roleName !== "admin") return <NotAuth />;

  const openSideMenue = () => {
    // console.log("openSideMenue");
    document.querySelector(".side-nav").classList.add("openmenu");
    document
      .querySelector(".backdropmenu")
      .classList.add("backdropmenu_active");
  };

  return (
    <div className="">
      <ToastContainer theme="colored" />
      <BackDrop />
      <div className="main_content">
        <MobileIcon openSideMenue={openSideMenue} />
        <SideNav />
        <div className="main_content_wrapper">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
