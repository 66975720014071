import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { Link, NavLink, useNavigate } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import HistoryIcon from "@mui/icons-material/History";
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loading from "./Loading";
import Cookies from "js-cookie";

function SideNav() {

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const usersprofile = useSelector(
  //   (state) => state.userProfileSlice.usersprofile
  // );
  const [userdata, setUserData] = useState({});
  const [loading, setLoading] = useState(false);

  const signOut = () => {
    setLoading(true);
    Cookies.remove("user_id");
    Cookies.remove("user_name");
    toast.success("تم تسجيل الخروج بنجاح");
    setLoading(false);
    navigate("/");
  };

  const handelBackDrop = () =>{
    document.querySelector(".backdropmenu").classList.remove("backdropmenu_active");
    document.querySelector('.side-nav').classList.remove('openmenu');
    // document.querySelector(".notification_component").classList.remove("notification_component_visible");
  }

  return (
    <>
      {loading ? <Loading open={true} /> : null}
      <div className="side-nav">
        <div className="cloe_nav" onClick={handelBackDrop}>
          <i className="fas fa-times"></i>
        </div>
        <div className="side_links">
          <ul>
            <li>
              <NavLink to="/dashboard" end>
                <HomeOutlinedIcon></HomeOutlinedIcon>
                <span>الرئيسية</span>
              </NavLink>
            </li>
            <li>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="accordion_summery"
                >
                  <Typography>
                    <span className="icon-emplyee"></span>
                    <span>اداره المستخدمين</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <NavLink to="/dashboard/all-users">
                      <PeopleOutlinedIcon></PeopleOutlinedIcon>
                        المستخدمين
                    </NavLink>
                    {/* <NavLink to="/dashboard/">
                      <PeopleOutlinedIcon></PeopleOutlinedIcon> 
                      مدراء الفرق
                    </NavLink> */}
                    {/* <NavLink to="/dashboard/add-user">
                      <PersonAddOutlinedIcon></PersonAddOutlinedIcon>اضافه مستخدم
                    </NavLink> */}
                    <NavLink to="/dashboard/add-user">
                      <PersonAddOutlinedIcon></PersonAddOutlinedIcon>اضافه 
                      مستخدم جديد
                    </NavLink>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </li>
            <li>
              <NavLink to="/dashboard/courses" end>
                <LibraryBooksOutlinedIcon></LibraryBooksOutlinedIcon>
                <span>الكورسات</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/dashboard/course-rigister" end>
                <CreateNewFolderOutlinedIcon></CreateNewFolderOutlinedIcon>
                <span>حجوزات الكورسات</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/dashboard/search-for-course" end>
                <SearchOutlinedIcon></SearchOutlinedIcon>
                <span>البحث عن الحجز</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/dashboard/course-completed" end>
                <AddTaskOutlinedIcon></AddTaskOutlinedIcon>
                <span>تاكيدات الحجز</span>
              </NavLink>
            </li>

            <li>
              <NavLink to="/dashboard/markaters" end>
                <PeopleOutlineOutlinedIcon></PeopleOutlineOutlinedIcon>
                <span>المسوقين</span>
              </NavLink>
            </li>

            <li>
              <NavLink to="/dashboard/customer-services" end>
                <SupportAgentOutlinedIcon></SupportAgentOutlinedIcon>
                <span>خدمة العملاء</span>
              </NavLink>
            </li>

            <li>
              <NavLink to="/dashboard/request-histories" end>
                <HistoryIcon></HistoryIcon>
                <span>سجل الحجوزات</span>
              </NavLink>
            </li>

            <li className="signout_btn">
              <a onClick={signOut}>
                <span>
                  <LogoutOutlinedIcon></LogoutOutlinedIcon>
                </span>
                <span>تسجيل الخروج</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default SideNav;
