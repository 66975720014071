import React, { useEffect, useState } from "react";
import DataGridDemo from "../components/DataTable";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { coursesGetAll, deleteCourse } from "../store/CourseSlice";
import { toast } from "react-toastify";
import { Delete } from "@mui/icons-material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import {
  customerServices,
  deleteCustomer,
} from "../store/customerServicesSlice";
import Cookies from "js-cookie";

function CustomerServices() {
  const selectionRows = false;
  const dispatch = useDispatch();
  const coursesrigisterCustomer = useSelector(
    (state) => state.requestsSlices.allrequests
  );
  const customerservices = useSelector(
    (state) => state.customerServicesSlice.customerservices
  );
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [loading, setLoading] = useState(true);
  const userRole = Cookies.get("user_role");
  const adminUser = userRole == 0 ? true : false;

  const handledelete = (id) => {
    confirmAlert({
      title: "تاكيد الحذف",
      message: "هل انت متاكد من ذلك",
      buttons: [
        {
          label: "تاكيد",
          onClick: () => {
            setLoading(true);
            dispatch(deleteCustomer(id))
              .unwrap()
              .then((data) => {
                toast.success("تم المسح بنجاح");
                setLoading(false);
              })
              .catch((error) => {
                toast.error(error.message);
                setLoading(false);
              });
          },
        },
        {
          label: "الغاء",
        },
      ],
    });
  };

  const coulmns = [
    { field: "name", headerName: "الاسم", editable: true, width: 200 },
  ];

  if (userRole == 1) {
    coulmns.push({
      field: "actions",
      headerName: "اختيارات",
      // width: 150,
      renderCell: (params) => (
        <div className="tableoptions">
          <span className="editicon">
            <Link to={`/supervisor/customer-services-courses/${params.row.id}`}>
              <FeedOutlinedIcon style={{ cursor: "pointer" }} />
            </Link>
          </span>
        </div>
      ),
    });
  }

  if (userRole == 0) {
    coulmns.push({
      field: "actions",
      headerName: "اختيارات",
      // width: 150,
      renderCell: (params) => (
        <div className="tableoptions">
          <span className="editicon">
            <Link to={`/dashboard/customer-services-courses/${params.row.id}`}>
              <FeedOutlinedIcon style={{ cursor: "pointer" }} />
            </Link>
          </span>
          <span className="deleteicon">
            <Delete
              onClick={() => handledelete(params.row.id)}
              style={{ cursor: "pointer" }}
            />
          </span>
        </div>
      ),
    });
  }

  const handleRowSelection = (selectionModel) => {
    setRowSelectionModel(selectionModel);
    // setInputs((prevInputs) => ({ ...prevInputs, district_id: selectionModel }));
    // console.log("Selected Rows:", selectionModel);

    // const selectedRowData = selectionModel.map((selectedId) =>
    //   rows.find((row) => row.id === selectedId)
    // );
    // console.log("Selected Row Data:", selectedRowData);
  };

  useEffect(() => {
    dispatch(customerServices())
      .unwrap()
      .then((res) => {
        // toast.success('')
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setLoading(false);
      });
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Loading open={true} />
      ) : (
        <div className="manage_employee">
          <div className="add_button">
            {userRole == 0 ? (
              <Link to="/dashboard/add-new-customer-services">اضافة عملاء</Link>
            ) : null}
          </div>
          <div className="table_show table_style">
            <h5>خدمه العملاء</h5>
            <DataGridDemo
              selectionRows={selectionRows}
              coulmns={coulmns}
              rows={customerservices}
              handleRowSelection={handleRowSelection}
              adminUser={adminUser}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default CustomerServices;
