import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import DataGridDemo from "../components/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { customerServices } from "../store/customerServicesSlice";
import { formRequestscustomerServiceId } from "../store/requestsSlices";
import { toast } from "react-toastify";
import { emptyCustomeReq } from "../store/customerServicesSlice";
import Cookies from "js-cookie";

function CustomerServicesCourse() {
  const selectionRows = false;
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customerId = params.id;
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [loading, setLoading] = useState(true);
  const userRole = Cookies.get("user_role");
  const adminUser = userRole == 0 ? true : false;
  const coursesrigisterService = useSelector(
    (state) => state.requestsSlices.requestscustomerServiceId
  );

  useEffect(() => {
    dispatch(formRequestscustomerServiceId(customerId))
      .unwrap()
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  }, [dispatch]);

  const handleRowSelection = (selectionModel) => {
    setRowSelectionModel(selectionModel);
    // setInputs((prevInputs) => ({ ...prevInputs, district_id: selectionModel }));
    // console.log("Selected Rows:", selectionModel);

    // const selectedRowData = selectionModel.map((selectedId) =>
    //   rows.find((row) => row.id === selectedId)
    // );
    // console.log("Selected Row Data:", selectedRowData);
  };

  const emptyRequests = () => {
    setLoading(true);
    dispatch(emptyCustomeReq(customerId))
      .unwrap()
      .then((data) => {
        toast.success("تم التفريغ بنجاح");
        setTimeout(() => {
          navigate("/dashboard/customer-services");
          setLoading(false);
        }, 1000);
      });
  };

  const coulmns = [
    { field: "arName", headerName: "الاسم عربي", width: 200 },
    {
      field: "enName",
      headerName: "الاسم انجليزي",
      editable: true,
      width: 200,
    },
    { field: "phone1", headerName: "الهاتف الاول", width: 200 },
    { field: "phone2", headerName: "الهاتف الثاني", width: 200 },
    { field: "education", headerName: "التعليم", width: 200 },
    { field: "country", headerName: "الدوله", width: 200 },
    { field: "city", headerName: "المدينه", width: 200 },
    {
      field: "courseModel",
      headerName: "الكورس",
      width: 150,
      renderCell: (params) => (
        <div className="">
          <span className="">{params.row?.courseModel?.name}</span>
        </div>
      ),
    },
    {
      field: "markterModel",
      headerName: "المسوق",
      width: 150,
      renderCell: (params) => (
        <div className="">
          <span className="">{params.row?.markterModel?.name}</span>
        </div>
      ),
    },
    {
      field: "customerServiceModel",
      headerName: "خدمه العملاء",
      width: 150,
      renderCell: (params) => (
        <div className="">
          <span className="">{params.row?.customerServiceModel?.name}</span>
        </div>
      ),
    },
    {
      field: "orderStatusModel",
      headerName: "حاله الحجز",
      width: 150,
      renderCell: (params) => (
        <div className="">
          <span className="">{params.row?.orderStatusModel?.name}</span>
        </div>
      ),
    },
    {
      field: "isCalled",
      headerName: "حاله الاتصال",
      width: 150,
      renderCell: (params) => (
        <div className="">
          <span className="">
            {params.row.isCalled ? (
              <span className="callDone">تم الاتصال</span>
            ) : (
              <span className="callNotDone">لم يتم الاتصال</span>
            )}
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      {loading ? (
        <Loading open={true} />
      ) : (
        <div className="manage_employee">
          <div className="table_show table_style">
            <h5>الحجوزات</h5>

            {userRole == 0 ? (
              coursesrigisterService.length == 0 ? (
                ""
              ) : (
                <div className="empty_courses">
                  <button className="btn_style" onClick={emptyRequests}>
                    تفريغ الحجوزات
                  </button>
                </div>
              )
            ) : null}

            <DataGridDemo
              selectionRows={selectionRows}
              coulmns={coulmns}
              rows={coursesrigisterService}
              handleRowSelection={handleRowSelection}
              adminUser={adminUser}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default CustomerServicesCourse;
