import React from 'react'

function FilterToDownload({filterDataToDownload}) {
  return (
    <div>

        <button className='btn_style' onClick={filterDataToDownload}>تحميل الملف</button>

    </div>
  )
}

export default FilterToDownload