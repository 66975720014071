import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../components/Loading";
import DataGridDemo from "../components/DataTable";
import { toast } from "react-toastify";
import {
  confirmRequest,
  searchForCourse,
  searchForCourseChange,
} from "../store/searchForCourseSlice";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { useNavigate } from "react-router";
import { customerServices } from "../store/customerServicesSlice";
import Pagination from "@mui/material/Pagination";

function SearchForCourse() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const customerservices = useSelector(
    (state) => state.customerServicesSlice.customerservices
  );
  const [customerId, setCustomerId] = useState("");
  const selectionRows = false;
  const [phone, setPhone] = useState();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const requestResult = useSelector(
    (state) => state.searchForCourseSlice.requestresults
  );

  var modifiedArray = requestResult.map((item) => ({
    ...item, // Keep all the existing properties
    courseName: item?.courseModel?.name, // Add courseName property
    markterName: item?.markterModel?.name, // Add markterName property
    orderStatusName: item?.orderStatusModel?.name,
    orderDate: item.timeCreated != null ? item?.timeCreated.split("T")[0] : "",
    orderTime: item.timeCreated != null ? item?.timeCreated.split("T")[1] : "",
    iscalled2: item.isCalled ? "تم الاصال" : "لم يتم الاتصال",
  }));

  const confirmtheRequest = (requestId) => {
    console.log(requestId, customerId);
    setLoading(true);
    dispatch(confirmRequest({ requestId: requestId, customerId: customerId }))
      .unwrap()
      .then((res) => {
        console.log(res);
        toast.success("تم التاكيد بنجاح");
        setLoading(false);
        // navigate("/dashboard/course-completed");
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };

  const handelCustomerChange = (e) => {
    console.log(e.target.value);
    setCustomerId(e.target.value);
  };

  useEffect(() => {
    dispatch(customerServices())
      .unwrap()
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  }, [dispatch]);

  const coulmns = [
    {
      field: "orderDate",
      headerName: "التاريخ",
      width: 150,
    },
    {
      field: "orderTime",
      headerName: "الوقت",
      width: 150,
    },
    { field: "arName", headerName: "الاسم", width: 200 },
    { field: "phone1", headerName: "الهاتف الاول", width: 200 },
    { field: "phone2", headerName: "الهاتف الثاني", width: 200 },

    {
      field: "courseName",
      headerName: "الكورس",
      width: 150,
    },
    {
      field: "iscalled2",
      headerName: "حاله الاتصال",
      width: 150,
    },
    {
      field: "orderStatusName",
      headerName: "حاله الحجز",
      width: 150,
    },
    {
      field: "markterName",
      headerName: "المسوق",
      width: 150,
    },

    { field: "education", headerName: "التعليم", width: 200 },
    { field: "country", headerName: "الدوله", width: 200 },
    { field: "city", headerName: "المدينه", width: 200 },
    {
      field: "customerService",
      headerName: "خدمة العملاء",
      width: 190,
      renderCell: (params) => (
        <div className="">
          <span className="customerSelect">
            <select onChange={handelCustomerChange}>
              <option value="">اختر</option>
              {customerservices.map((customer) => (
                <option value={customer.id}>{customer.name}</option>
              ))}
            </select>
          </span>
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "اختيارات",
      // width: 150,
      renderCell: (params) => (
        <div className="tableoptions">
          <span className="completeicon">
            <CheckOutlinedIcon
              onClick={() => confirmtheRequest(params.row.id)}
              style={{ cursor: "pointer" }}
            />
          </span>
        </div>
      ),
    },
  ];

  const handelInputChange = (event) => {
    const { name, value } = event.target;
    setPhone(value);
    // Send a request if the input length is a multiple of 3
    if (value.length % 1 === 0) {
      const chunk = value.substring(value.length - 1);
      console.log("Sending request for: " + chunk);
      searchphoneFunction(value);
    }
  };

  const searchphoneFunction = (value) => {
    setLoading(true);
    dispatch(searchForCourseChange({ phone, pageNumber: page }))
      .unwrap()
      .then((res) => {
        console.log(res);
        setLoading(false);
        setPageCount(res.totalPages);
      })
      .catch((err) => {
        toast.error(err.response.data.title);
        setLoading(false);
      });
  };

  const saveData = (e) => {
    e.preventDefault();
    setLoading(true);
    const newPhone = phone.replace("+", "");
    dispatch(searchForCourse(newPhone))
      .unwrap()
      .then((res) => {
        console.log(res);
        setLoading(false);
        setPageCount(res.totalPages);
      })
      .catch((err) => {
        toast.error(err.response.data.title);
        setLoading(false);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
    searchphoneFunction(value);
  };

  return (
    <>
      {loading ? <Loading open={true} /> : null}
      <div className="form_style">
        <div className="form_header">
          <h5>البحث عن حجز</h5>
          <p>يرجي ادخال البيانات التالية</p>
        </div>
        <form action="" onSubmit={saveData}>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="form-group">
                <input
                  type="number"
                  onChange={handelInputChange}
                  name="phone"
                  value={phone}
                  id=""
                  className="form-control"
                  placeholder="رقم الهاتف"
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="btn_submit">
                <button className="btn_style">بحث</button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="manage_employee">
        <div className="table_show table_style">
          <h5>حجوزات الكورسات</h5>
          <DataGridDemo
            selectionRows={selectionRows}
            coulmns={coulmns}
            rows={modifiedArray}
          />
          <Pagination
            count={pageCount}
            page={page}
            onChange={handleChange}
            color="primary"
            className="paginaitonMod"
          />
        </div>
      </div>
    </>
  );
}

export default SearchForCourse;
