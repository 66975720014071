import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://backend.egy-courses.com/api/";

export const customerServicesLogin = createAsyncThunk(
  "customer-services/login",
  async (customerserviceData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.post(
        `${endpoint}customer-services/login`,
        customerserviceData
      );
      const data = await response.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const customerServices = createAsyncThunk(
  "admin/customer-services",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${endpoint}CustomerServices`);
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNewCustomer = createAsyncThunk(
  "admin/add-new-customer",
  async (cusData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.post(`${endpoint}CustomerServices`, cusData);
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "admin/delete-customer",
  async (custId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.delete(`${endpoint}CustomerServices/${custId}`);
      return custId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const emptyCustomeReq = createAsyncThunk(
  "admin/empty-customer-req",
  async (customerId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.post(
        `${endpoint}Statistics/RemoveCustomerServicesFromRequests/${customerId}`
      );
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const customerServicesSlice = createSlice({
  name: "admin",
  initialState: {
    isLoading: true,
    customerservices: [],
  },
  reducers: {},
  extraReducers: {
    [customerServices.pending]: (state, action) => {
      state.isLoading = true;
    },
    [customerServices.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.customerservices = action.payload;
    },
    [customerServices.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [addNewCustomer.pending]: (state, action) => {
      state.isLoading = true;
    },
    [addNewCustomer.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [addNewCustomer.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [deleteCustomer.pending]: (state, action) => {
      state.isLoading = true;
    },
    [deleteCustomer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.customerservices = state.customerservices.filter(
        (custom) => custom.id !== action.payload
      );
    },
    [deleteCustomer.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // emptyCustomeReq
    [emptyCustomeReq.pending]: (state, action) => {
      state.isLoading = true;
    },
    [emptyCustomeReq.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [emptyCustomeReq.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export default customerServicesSlice.reducer;
