import React from 'react'

function StaticsCardTeamLead({marktertatistics, courseName}) {
  return (
    <div className="statics_card">
      <h5>{courseName}</h5>
      <p className="number">{marktertatistics.unCalledCount}</p>
      {/* <CircularChart statics={statics.districts} label="احياء" /> */}
    </div>
  )
}

export default StaticsCardTeamLead