import React from 'react'

function BackDrop() {

  const handelBackDrop = () =>{
    document.querySelector(".backdropmenu").classList.remove("backdropmenu_active");
    document.querySelector('.side-nav').classList.remove('openmenu');
    // document.querySelector(".notification_component").classList.remove("notification_component_visible");
  }

  return (
    <div className="backdropmenu" onClick={handelBackDrop}></div>
    )
}

export default BackDrop