import React from "react";
import { Outlet } from "react-router-dom";
import SideNav from "../../components/SideNav";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import MobileIcon from "../../components/MobileIcon";
import BackDrop from '../../components/BackDrop';
import SupervisorSidenav from "../../components/SupervisorSidenav";
import NotAuth from "../NotAuth";
import App from "../../App";
import TeamLeaderSidenav from "../../components/TeamLeaderSidenav";
import TeamLeaderLogin from "./TeamLeaderLogin";

function TeamLeader() {
  const userId = Cookies.get("markter_id");
  const roleName = Cookies.get("markter_role");
  const userRole = Cookies.get("user_role");
  // const userName = Cookies.get("markaterRole");
  console.log(userId, roleName, userRole);
  if (userId == null || userId == undefined) return <TeamLeaderLogin />;
  if (roleName == "" || roleName == undefined) return <TeamLeaderLogin />;
  

  if (userRole !== "4") return <NotAuth />;
  if (roleName !== "teamleader") return <NotAuth />;


  const openSideMenue = () => {
    // console.log("openSideMenue");
    document.querySelector(".side-nav").classList.add("openmenu");
    document
      .querySelector(".backdropmenu")
      .classList.add("backdropmenu_active");
  };

  return (
    <>
      <ToastContainer theme="colored" />
      <div className="">
        <BackDrop />
        <div className="main_content">
          <MobileIcon openSideMenue={openSideMenue} />
          <TeamLeaderSidenav />
          <div className="main_content_wrapper">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamLeader;
