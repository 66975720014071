import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://backend.egy-courses.com/api/";

export const searchForCourse = createAsyncThunk(
  "courses/searchcourse",
  async (phone, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(
        `${endpoint}FormRequests?phone=${phone}&statusId=4eb73a8b-1cbf-4829-f25c-08dbc710612b&needPage=true&pageSize=100&startDate=2020-01-01&endDate=${new Date()
          .toLocaleDateString()
          .replaceAll("/", "-")}`
      );
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchForCourseChange = createAsyncThunk(
  "courses/searchcourse",
  async ({ phone, pageNumber }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(
        `${endpoint}FormRequests?phone=${phone}&statusId=4eb73a8b-1cbf-4829-f25c-08dbc710612b&needPage=true&pageNumber=${pageNumber}&pageSize=100&startDate=2020-01-01&endDate=${new Date()
          .toLocaleDateString()
          .replaceAll("/", "-")}`
      );
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const confirmRequest = createAsyncThunk(
  "courses/confirmrequest",
  async ({ requestId, customerId }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.put(
        `${endpoint}FormRequests/UpdateStatus/${requestId}?orderStatusId=1affc0f8-946e-4730-f25d-08dbc710612b&customerServicsId=${customerId}`
      );
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const searchForCourseSlice = createSlice({
  name: "courses",
  initialState: {
    isLoading: true,
    requestresults: [],
  },
  reducers: {},
  extraReducers: {
    [searchForCourse.pending]: (state, action) => {
      state.isLoading = true;
    },
    [searchForCourse.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.requestresults = action.payload.items;
    },
    [searchForCourse.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // searchForCourseChange
    [searchForCourseChange.pending]: (state, action) => {
      state.isLoading = true;
    },
    [searchForCourseChange.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.requestresults = action.payload.items;
    },
    [searchForCourseChange.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // confirmRequest
    [confirmRequest.pending]: (state, action) => {
      state.isLoading = true;
    },
    [confirmRequest.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [confirmRequest.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export default searchForCourseSlice.reducer;
