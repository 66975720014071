import React, { useEffect, useState } from "react";
import DataGridDemo from "../components/DataTable";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { coursesGetAll, deleteCourse } from "../store/CourseSlice";
import { toast } from "react-toastify";
import { Delete } from "@mui/icons-material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Cookies from "js-cookie";

function Courses() {
  const selectionRows = false;
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.courseSlice.courses);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [loading, setLoading] = useState(true);
  const userRole = Cookies.get("user_role");
  const adminUser = userRole == 0 ? true : false;

  const handledelete = (id) => {
    confirmAlert({
      title: "تاكيد الحذف",
      message: "هل انت متاكد من ذلك",
      buttons: [
        {
          label: "تاكيد",
          onClick: () => {
            setLoading(true);
            dispatch(deleteCourse(id))
              .unwrap()
              .then((data) => {
                toast.success("تم المسح بنجاح");
                setLoading(false);
              })
              .catch((error) => {
                toast.error(error.message);
                setLoading(false);
              });
          },
        },
        {
          label: "الغاء",
        },
      ],
    });
  };

  const coulmns = [
    { field: "imageUrl", headerName: "الصوره", width: 200 },
    { field: "name", headerName: "الاسم", editable: true, width: 200 },
    { field: "description", headerName: "الوصف", width: 200 },
    { field: "price", headerName: "السعر", width: 200 },
    { field: "isActive", headerName: "نشط", width: 200 },
    { field: "commission", headerName: "النسبه", width: 200 },
  ];

  if (userRole == 0) {
    coulmns.push({
      field: "actions",
      headerName: "اختيارات",
      // width: 150,
      renderCell: (params) => (
        <div className="tableoptions">
          <span className="editicon">
            <Link to={`/dashboard/edit-course/${params.row.id}`}>
              <CreateOutlinedIcon style={{ cursor: "pointer" }} />
            </Link>
          </span>
          <span className="deleteicon">
            <Delete
              onClick={() => handledelete(params.row.id)}
              style={{ cursor: "pointer" }}
            />
          </span>
        </div>
      ),
    });
  }

  const handleRowSelection = (selectionModel) => {
    setRowSelectionModel(selectionModel);
    // setInputs((prevInputs) => ({ ...prevInputs, district_id: selectionModel }));
    // console.log("Selected Rows:", selectionModel);

    // const selectedRowData = selectionModel.map((selectedId) =>
    //   rows.find((row) => row.id === selectedId)
    // );
    // console.log("Selected Row Data:", selectedRowData);
  };

  useEffect(() => {
    dispatch(coursesGetAll())
      .unwrap()
      .then((res) => {
        // toast.success('')
        console.log(res)
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setLoading(false);
      });
  }, [dispatch]);

  console.log(adminUser)

  return (
    <>
      {loading ? <Loading open={true} /> : null}
      <div className="manage_employee">
        <div className="add_button">
          {userRole == 0 ? (
            <Link to="/dashboard/add-new-course">اضافة كورس</Link>
          ) : null}
        </div>
        <div className="table_show table_style">
          <h5>الكورسات</h5>
          <DataGridDemo
            selectionRows={selectionRows}
            coulmns={coulmns}
            rows={courses}
            handleRowSelection={handleRowSelection}
            adminUser={adminUser}
          />
        </div>
      </div>
    </>
  );
}

export default Courses;
