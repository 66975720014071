import React, { useEffect, useRef, useState } from "react";
import { Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { toast } from "react-toastify";
import { adminLogin } from "../store/adminSlice";
import Cookies from "js-cookie";

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [, forceUpdate] = useState();
  const dispatch = useDispatch();
  const [creditial, setCreditional] = useState({
    userName: "",
    password: "",
  });

  useEffect(() => {
    Cookies.remove("user_id");
    Cookies.remove("user_name");
  }, []);

  const handelInputChange = (event) => {
    const { name, value } = event.target;
    setCreditional((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const senToLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(adminLogin(creditial))
      .unwrap()
      .then((res) => {
        console.log(res);
        Cookies.set("user_id", res.id);
        Cookies.set("user_name", res.userName);
        Cookies.set("role_name", res.name);
        Cookies.set("user_role", res.role);
        if (res.name == "admin" && res.role == 0) {
          toast.success("تم تسجيل الدخول بنجاح");
          console.log(res.name);
          console.log(res.role);
          setTimeout(() => {
            navigate("/dashboard");
            setLoading(false);
            window.location.reload();
          }, 1500);
        } else if (res.name == "supervisor" && res.role == 1) {
          toast.success("تم تسجيل الدخول بنجاح");

          console.log(res.name);
          console.log(res.role);
          setTimeout(() => {
            navigate("/supervisor");
            setLoading(false);
            window.location.reload();
          }, 1500);
        } else {
          toast.error("تاكد من البيانات");
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data);
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? <Loading open={true} /> : null}
      <div className="login_page">
        <img
          className="wavelogin"
          src={`${process.env.PUBLIC_URL}/images/wavelogin.svg`}
          alt=""
        />
        <Container maxWidth="xxl">
          <div className="login_form">
            {/* <div className="logo">
                <img src={`${process.env.PUBLIC_URL}/images/logo.svg`} alt="" />
              </div> */}
            <form action="" onSubmit={senToLogin}>
              <div className="form-group">
                <label htmlFor="">اسم المستخدم</label>
                <input
                  name="userName"
                  value={creditial.userName}
                  type="text"
                  className="form-control"
                  placeholder="omar ali"
                  onChange={handelInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">كلمه المرور</label>
                <input
                  name="password"
                  value={creditial.password}
                  type="password"
                  className="form-control"
                  placeholder="**********"
                  onChange={handelInputChange}
                />
              </div>
              <div className="btn_submit">
                <button className="btn">تسجيل الدخول</button>
              </div>
            </form>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Login;
