import React, { useEffect, useRef, useState } from "react";
import { Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "js-cookie";
import { customerServicesLogin } from "../../store/customerServicesSlice";

function CustomerServiceLogin() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [, forceUpdate] = useState();
  const dispatch = useDispatch();
  const [creditial, setCreditional] = useState({
    phone: "",
    password: "",
  });

  useEffect(() => {
    Cookies.remove("Cust_id");
    Cookies.remove("Cust_role");
  }, []);

  const handelInputChange = (event) => {
    const { name, value } = event.target;
    setCreditional((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const senToLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(customerServicesLogin(creditial))
      .unwrap()
      .then((res) => {
        console.log(res, "hello");

        if (res.isTeamLeader) {
          Cookies.set("customer_service_id", res.id);
          Cookies.set("user_role", 8);
          Cookies.set("role_name", "customer_service");
          toast.success(`تم تسجيل الدخول بنجاح`);
          setTimeout(() => {
            navigate("/customerService/dashboard");
            setLoading(false);
            window.location.reload();
          }, 1500);
        } else {
          toast.error("هذا ليس حساب خدمة عملاء");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "hi");
        toast.error(err);
        setLoading(false);
      });
  };

  return (
    <>
      <ToastContainer theme="colored" />
      {loading ? <Loading open={true} /> : null}
      <div className="login_page">
        <img
          className="wavelogin"
          src={`${process.env.PUBLIC_URL}/images/wavelogin.svg`}
          alt=""
        />
        <Container maxWidth="xxl">
          <div className="login_form">
            <h5>تسجيل دخول خدمة العملاء </h5>
            {/* <div className="logo">
                <img src={`${process.env.PUBLIC_URL}/images/logo.svg`} alt="" />
              </div> */}
            <form action="" onSubmit={senToLogin}>
              <div className="form-group">
                <label htmlFor="">رقم الهاتف</label>
                <input
                  name="phone"
                  value={creditial.phone}
                  type="text"
                  className="form-control"
                  placeholder="0****"
                  onChange={handelInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">كلمه المرور</label>
                <input
                  name="password"
                  value={creditial.password}
                  type="password"
                  className="form-control"
                  placeholder="**********"
                  onChange={handelInputChange}
                />
              </div>
              <div className="btn_submit">
                <button className="btn">تسجيل الدخول</button>
              </div>
            </form>
          </div>
        </Container>
      </div>
    </>
  );
}

export default CustomerServiceLogin;
