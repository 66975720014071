import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://backend.egy-courses.com/api/";

export const getcoursestatiscs = createAsyncThunk(
  "statiscs/get-course-statiscs",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${endpoint}Statistics/FormReqCourses`);
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getcoursecountrystatiscs = createAsyncThunk(
  "statiscs/get-course-country-statiscs",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${endpoint}Statistics/CountryStats`);
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMarkaterStaticsall = createAsyncThunk(
  "statiscs/get-markater-statiscs",
  async (markterId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(
        `${endpoint}Statistics/FormReqCourses?markterId=${markterId}`
      );
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMarkaterStatics = createAsyncThunk(
  "statiscs/get-markater-statiscs-all",
  async (markterId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(
        `${endpoint}Statistics/FormReqMarketers?marketerId=${markterId}`
      );
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const statiscSlice = createSlice({
  name: "statiscs",
  initialState: {
    isLoading: true,
    courseStatistics: [],
    courseCountryStatistics: {},
    markterStatistics: [],
    markterStatisticsall: [],
  },
  reducers: {},
  extraReducers: {
    [getcoursestatiscs.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getcoursestatiscs.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.courseStatistics = action.payload;
    },
    [getcoursestatiscs.rejected]: (state, action) => {
      state.isLoading = false;
    },


    // getcoursecountrystatiscs
    [getcoursecountrystatiscs.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getcoursecountrystatiscs.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.courseCountryStatistics = action.payload;
    },
    [getcoursecountrystatiscs.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getMarkaterStatics
    [getMarkaterStatics.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getMarkaterStatics.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.markterStatistics = action.payload;
    },
    [getMarkaterStatics.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getMarkaterStaticsall
    [getMarkaterStaticsall.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getMarkaterStaticsall.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.markterStatisticsall = action.payload;
    },
    [getMarkaterStaticsall.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export default statiscSlice.reducer;
