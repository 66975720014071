import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getmarkaters } from "../store/marketersSlice";
import Loading from "./Loading";
import { toast } from "react-toastify";
import { coursesGetAll } from "../store/CourseSlice";
import { formRequests } from "../store/requestsSlices";
import Select from "react-select";

function Filter({ page, setPage, filterInputs, setFilterInputs, filterData }) {
  const dispatch = useDispatch();
  const marketers = useSelector((state) => state.marketersSlice.markaters);
  const courses = useSelector((state) => state.courseSlice.courses);
  const [loading, setLoading] = useState(true);
  const [optionselect, setOptionSelect] = useState([]);
  const [marketerselect, setMarketerselect] = useState([]);

  useEffect(() => {
    dispatch(getmarkaters())
      .unwrap()
      .then((data) => {
        // toast.success('')
        const newMarketers = data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setMarketerselect(newMarketers);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setLoading(false);
      });

    dispatch(coursesGetAll())
      .unwrap()
      .then((data) => {
        const newCourses = data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        // console.log(newSkills);
        setOptionSelect(newCourses);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setLoading(false);
      });
  }, [dispatch]);

  const handelChange = (e) => {
    const { name, value } = e.target;
    setFilterInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const onSelectChange = (value, action) => {
    const courseIds = value.map((item) => item.value);
    let modifiedArray = courseIds.map((item) => "&courseIds=" + item).join("");
    setFilterInputs((prevInputs) => ({
      ...prevInputs,
      courseIds: modifiedArray,
    }));
  };

  const onMarketerChange = (value, action) => {
    const markaterId = value.value;
    setFilterInputs((prevInputs) => ({
      ...prevInputs,
      markterId: markaterId,
    }));
  };

  return (
    <>
      {loading ? (
        <Loading open={true} />
      ) : (
        <form action="" onSubmit={filterData}>
          <div className="form_wrapper">
            <div className="form_group">
              <label htmlFor="">المسوق</label>
              <Select
                closeMenuOnSelect={true}
                name="colors"
                // value={selectVal}
                options={marketerselect}
                className="basic-multi-select"
                classNamePrefix="select"
                id="SkillsTags"
                onChange={onMarketerChange}
                // onInputChange={handelFilterChange}
                // hideSelectedOptions={false}
              />
            </div>

            <div className="form_group">
              <label htmlFor="">حالة الاتصال</label>
              <select
                name="isCalled"
                id=""
                onChange={handelChange}
                value={filterInputs.isCalled}
                className="form_control"
              >
                <option value="0">اختر الحاله</option>
                <option value="true">تم الاتصال</option>
                <option value="false">لم يتم الاتصال</option>
              </select>
            </div>

            <div className="form_group">
              <label htmlFor="">تاريخ البدايه</label>
              <input
                type="date"
                name="startDate"
                id=""
                value={filterInputs.startDate}
                onChange={handelChange}
                className="form_control"
              />
            </div>
            <div className="form_group">
              <label htmlFor="">تاريخ النهايه</label>
              <input
                type="date"
                name="endDate"
                id=""
                value={filterInputs.endDate}
                onChange={handelChange}
                className="form_control"
              />
            </div>
          </div>
          <div className="form_group">
            <label htmlFor="">التدريبات</label>
            <Select
              closeMenuOnSelect={false}
              isMulti
              name="colors"
              // value={selectVal}
              options={optionselect}
              className="basic-multi-select"
              classNamePrefix="select"
              id="SkillsTags"
              onChange={onSelectChange}
              // onInputChange={handelFilterChange}
              // hideSelectedOptions={false}
            />
          </div>
          <div className="form_group">
            <button className="btn_style">تصفيه</button>
          </div>
        </form>
      )}
    </>
  );
}

export default Filter;
