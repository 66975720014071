import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://backend.egy-courses.com/api/";

export const adminLogin = createAsyncThunk(
  "admin/adminlogin",
  async (adminData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.post(`${endpoint}Admains/login`, adminData);
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    isLoading: true,
    adminData: {},
  },
  reducers: {},
  extraReducers: {
    [adminLogin.pending]: (state, action) => {
      state.isLoading = true;
    },
    [adminLogin.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.adminData = action.payload;
    },
    [adminLogin.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export default adminSlice.reducer;
