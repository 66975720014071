import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import TextField from "@mui/material/TextField";
import { Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import Loading from "../../components/Loading";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { toast } from "react-toastify";
import { getcoursestatiscs, getcoursecountrystatiscs } from "../store/statiscsSlice";
import Loading from "../components/Loading";
import StaticsCard from "../components/StaticsCard";

function Home() {
  const [loading, setLoading] = useState(true);
  const courseStatistics = useSelector((state) => state.statiscSlice.courseStatistics);
  const courseCountryStatistics = useSelector((state) => state.statiscSlice.courseCountryStatistics);
  const dispatch = useDispatch();
  console.log(courseCountryStatistics)
  useEffect(() => {
    dispatch(getcoursestatiscs()).unwrap().then((data) => {
      setLoading(false);
    })
    dispatch(getcoursecountrystatiscs()).unwrap().then((data) => {
      setLoading(false)
    })
  }, [dispatch])


  return (
    <>
      {loading ? <Loading open={true} /> : null}
      <div className="statics_wrapper">
        <div className="statics_cards">
          <Container maxWidth="xxl">
            <h5 className="statics_header">الاحصائيات</h5>

            <Grid container spacing={1} className="">
              <Grid xs={12} md={6}>
                <div className="statics_card">
                  <h5>المصريين</h5>
                  <p className="number">{courseCountryStatistics.egyptianCount}</p>
                </div>
              </Grid>

              <Grid xs={12} md={6}>
                <div className="statics_card">
                  <h5>غير مصريين</h5>
                  <p className="number">{courseCountryStatistics.nonEgyptianCount}</p>
                </div>
              </Grid>
            </Grid>


            <Grid container spacing={1} className="">
              {courseStatistics.map((course) => (
                <Grid xs={12} md={4} key={course.id}>
                  <StaticsCard course={course} />
                </Grid>
              ))}

            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Home;
