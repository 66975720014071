import {configureStore} from '@reduxjs/toolkit';
import courseSlice from './CourseSlice';
import requestsSlices from './requestsSlices';
import searchForCourseSlice from './searchForCourseSlice';
import requestscompleteSlice from './requestscompleteSlice'
import customerServicesSlice from './customerServicesSlice'
import statiscSlice from './statiscsSlice';
import marketersSlice from './marketersSlice';
import usersSlice from './usersSlice';
export default configureStore({
  reducer: {
    courseSlice,
    requestsSlices,
    searchForCourseSlice,
    requestscompleteSlice,
    customerServicesSlice,
    statiscSlice,
    marketersSlice,
    usersSlice,
  }
})