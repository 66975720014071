import React from "react";

function NotAuth() {
  return (
    <div className="noauth">
      <div className="wrap">
        <img src={`${process.env.PUBLIC_URL}/images/shield.jpg`} alt="" />
        <h5>You don't have premission to enter this page</h5>
      </div>
    </div>
  );
}

export default NotAuth;
