import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://backend.egy-courses.com/api/";

export const requestscomplete = createAsyncThunk(
  "courses/formrequests",
  async ({ startDate, endDate, pageNumber }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(
        `${endpoint}FormRequests?statusId=1affc0f8-946e-4730-f25d-08dbc710612b&needPage=true&startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=100`
      );
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const requestscompleteFilter = createAsyncThunk(
  "courses/formrequests-filter",
  async ({ startDate, endDate, pageNumber }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(
        `${endpoint}FormRequests?statusId=1affc0f8-946e-4730-f25d-08dbc710612b&needPage=false&allData=true&startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=100`
      );
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteRequest = createAsyncThunk(
  "courses/deleterequest",
  async (requestId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.put(
        `${endpoint}FormRequests/UpdateStatus/${requestId}?orderStatusId=001d0ce7-d35b-46dc-f25e-08dbc710612b`
      );
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const emptyCompletedRigisterd = createAsyncThunk(
  "courses/deleterequest",
  async ({ startDate, endDate }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.post(
        `${endpoint}Statistics/ChangeOrderStatusFromRequests?OrderStatusId=1affc0f8-946e-4730-f25d-08dbc710612b&OldOrderStatusId=b711f013-8a67-4afe-b4c6-08dc0d67a5d5&fromDate=${startDate}&toDate=${endDate}`
      );
      const data = await res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const requestscompleteSlice = createSlice({
  name: "courses",
  initialState: {
    isLoading: true,
    allcompleterequests: [],
  },
  reducers: {},
  extraReducers: {
    [requestscomplete.pending]: (state, action) => {
      state.isLoading = true;
    },
    [requestscomplete.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allcompleterequests = action.payload.items;
    },
    [requestscomplete.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // deleteRequest
    [deleteRequest.pending]: (state, action) => {
      state.isLoading = true;
    },
    [deleteRequest.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [deleteRequest.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // emptyCompletedRigisterd
    [emptyCompletedRigisterd.pending]: (state, action) => {
      state.isLoading = true;
    },
    [emptyCompletedRigisterd.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [emptyCompletedRigisterd.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // requestscompleteFilter
    [requestscompleteFilter.pending]: (state, action) => {
      state.isLoading = true;
    },
    [requestscompleteFilter.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [requestscompleteFilter.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export default requestscompleteSlice.reducer;
