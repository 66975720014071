import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://backend.egy-courses.com/api/";

export const formRequests = createAsyncThunk(
  "courses/formrequests",
  async (
    {
      markterId,
      courseId,
      startDate,
      endDate,
      pageNumber,
      courseIds,
      isCalled,
      statusId,
      startChangeDate,
      endChangeDate
    },
    thunkAPI
  ) => {
    const { rejectWithValue } = thunkAPI;
    if (courseIds.length > 0) {
      try {
        const res = await axios.get(
          `${endpoint}FormRequests?statusId=${statusId}&markterId=${markterId}&needPage=true&allData=false&courseId=${courseId}&startDate=${startDate}&endDate=${endDate}${courseIds}&isCalled=${isCalled}&pageNumber=${pageNumber}&startChangeDate=${startChangeDate}&endChangeDate=${endChangeDate}&pageSize=100`
        );
        const data = await res.data;
        // console.log(data);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    } else {
      try {
        const res = await axios.get(
          `${endpoint}FormRequests?statusId=${statusId}&markterId=${markterId}&needPage=true&courseId=${courseId}&startDate=${startDate}&endDate=${endDate}&isCalled=${isCalled}&pageNumber=${pageNumber}&startChangeDate=${startChangeDate}&endChangeDate=${endChangeDate}&pageSize=100`
        );
        const data = await res.data;
        // console.log(data);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  }
);

export const formRequestsToDownload = createAsyncThunk(
  "courses/formrequeststodownload",
  async (
    {
      markterId,
      courseId,
      startDate,
      endDate,
      pageNumber,
      courseIds,
      isCalled,
    },
    thunkAPI
  ) => {
    const { rejectWithValue } = thunkAPI;
    if (courseIds.length > 0) {
      try {
        const res = await axios.get(
          `${endpoint}FormRequests?markterId=${markterId}&needPage=false&allData=true&courseId=${courseId}&startDate=${startDate}&endDate=${endDate}${courseIds}&isCalled=${isCalled}&pageNumber=${pageNumber}&pageSize=10`
        );
        const data = await res.data;
        // console.log(data);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    } else {
      try {
        const res = await axios.get(
          `${endpoint}FormRequests?markterId=${markterId}&needPage=false&allData=true&courseId=${courseId}&startDate=${startDate}&endDate=${endDate}&isCalled=${isCalled}&pageNumber=${pageNumber}&pageSize=10`
        );
        const data = await res.data;
        // console.log(data);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  }
);

export const formRequestscustomerServiceId = createAsyncThunk(
  "courses/formrequestscustomerServiceId",
  async (customerId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(
        `${endpoint}FormRequests?customerServiceId=${customerId}`
      );
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRequestPage = createAsyncThunk(
  "courses/formrequestscustomerServiceId",
  async (customerId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(
        `${endpoint}FormRequests?customerServiceId=${customerId}`
      );
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const requestHistoryFilter = createAsyncThunk(
  "courses/requestHistoryFilter",
  async ({ startDate, endDate, pageNumber, statusId }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(
        `${endpoint}FormRequests?statusId=${statusId}&needPage=true&startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=100`
      );
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// requestHistoryFilterToDownload
export const requestHistoryFilterToDownload = createAsyncThunk(
  "courses/requestHistoryFilterToDownload",
  async ({ startDate, endDate, pageNumber, statusId }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(
        `${endpoint}FormRequests?statusId=${statusId}&needPage=false&allData=true&startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=100`
      );
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const requestStatus = createAsyncThunk(
  "courses/request-status",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(
        `${endpoint}OrderStatus`
      );
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// https://backend.egy-courses.com/api/FormRequests?pageNumber=1&pageSize=100&needPage=true&allData=false&needHisTeam=false&needNumbersOnly=false

const requestsSlices = createSlice({
  name: "courses",
  initialState: {
    isLoading: true,
    allrequests: [],
    requestscustomerServiceId: [],
    allDataFilter: [],
    orderStatus: [],
  },
  reducers: {},
  extraReducers: {
    [formRequests.pending]: (state, action) => {
      state.isLoading = true;
    },
    [formRequests.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allrequests = action.payload.items;
    },
    [formRequests.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // formRequestsToDownload
    [formRequestsToDownload.pending]: (state, action) => {
      state.isLoading = true;
    },
    [formRequestsToDownload.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allDataFilter = action.payload.items;
    },
    [formRequestsToDownload.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [formRequestscustomerServiceId.pending]: (state, action) => {
      state.isLoading = true;
    },
    [formRequestscustomerServiceId.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.requestscustomerServiceId = action.payload;
    },
    [formRequestscustomerServiceId.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // requestHistoryFilter
    [requestHistoryFilter.pending]: (state, action) => {
      state.isLoading = true;
    },
    [requestHistoryFilter.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allrequests = action.payload.items;
    },
    [requestHistoryFilter.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // requestHistoryFilterToDownload
    [requestHistoryFilterToDownload.pending]: (state, action) => {
      state.isLoading = true;
    },
    [requestHistoryFilterToDownload.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [requestHistoryFilterToDownload.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // requestStatus
    [requestStatus.pending]: (state, action) => {
      state.isLoading = true;
    },
    [requestStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.orderStatus = action.payload;
    },
    [requestStatus.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export default requestsSlices.reducer;
