import React, { useEffect, useState } from "react";
import DataGridDemo from "../../components/DataTable";
import Loading from "../../components/Loading";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { coursesGetAll, deleteCourse } from "../../store/CourseSlice";
import { toast } from "react-toastify";
import { Delete } from "@mui/icons-material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import {
  deleteMarkater,
  getmarkaters,
  getmarkatersForTeamLeader,
} from "../../store/marketersSlice";
import Cookies from "js-cookie";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

function TeamLeaderHome() {
  const selectionRows = false;
  const dispatch = useDispatch();
  const [marketers, setnewMarkater] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [loading, setLoading] = useState(true);
  const markaterId = Cookies.get("markter_id");
  console.log(markaterId);

  const handledelete = (id) => {
    confirmAlert({
      title: "تاكيد الحذف",
      message: "هل انت متاكد من ذلك",
      buttons: [
        {
          label: "تاكيد",
          onClick: () => {
            setLoading(true);
            dispatch(deleteMarkater(id))
              .unwrap()
              .then((data) => {
                toast.success("تم المسح بنجاح");
                window.location.reload();
                setLoading(false);
              })
              .catch((error) => {
                toast.error(error.message);
                setLoading(false);
              });
          },
        },
        {
          label: "الغاء",
        },
      ],
    });
  };

  const coulmns = [
    { field: "name", headerName: "الاسم", editable: false, width: 200 },
    { field: "phone", headerName: "رقم الهاتف", editable: false, width: 200 },
    {
      field: "password",
      headerName: "الرقم السري",
      editable: false,
      width: 200,
    },
    {
      field: "isTeamLeader",
      headerName: "المنصب",
      editable: false,
      width: 200,
    },
    {
      field: "marketingUrl",
      headerName: "رابط التسويق",
      editable: false,
      width: 800,
      // renderCell: (params) => (
      //   <span>{`http://walidelmasry-002-site3.atempurl.com/affiliatemarketing/${params.row.id}`}</span>
      // ),
    },
    {
      field: "actions",
      headerName: "اختيارات",
      width: 250,
      renderCell: (params) => (
        <div className="tableoptions">
          <span className="editicon">
            <Link to={`/teamleader/dashboard/team-data/${params.row.id}`}>
              <FeedOutlinedIcon style={{ cursor: "pointer" }} />
            </Link>
          </span>

          <span className="deleteicon">
            <Delete
              onClick={() => handledelete(params.row.id)}
              style={{ cursor: "pointer" }}
            />
          </span>
        </div>
      ),
    },
  ];

  const handleRowSelection = (selectionModel) => {
    setRowSelectionModel(selectionModel);
    // setInputs((prevInputs) => ({ ...prevInputs, district_id: selectionModel }));
    // console.log("Selected Rows:", selectionModel);

    // const selectedRowData = selectionModel.map((selectedId) =>
    //   rows.find((row) => row.id === selectedId)
    // );
    // console.log("Selected Row Data:", selectedRowData);
  };

  useEffect(() => {
    dispatch(getmarkatersForTeamLeader(markaterId))
      .unwrap()
      .then((res) => {
        // toast.success('')
        console.log(res);
        setLoading(false);
        const newData = res.map((obj) => ({
          ...obj, // Copy the existing object properties
          marketingUrl: `https://markting.egy-courses.com/affiliatemarketing/${obj.id}`,
          isTeamLeader: obj.isTeamLeader ? "مدير فريق" : "مسوق",
        }));
        setnewMarkater(newData);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setLoading(false);
      });
  }, [dispatch]);

  return (
    <>
      {loading ? <Loading open={true} /> : null}

      <div className="manage_employee">
        <div className="table_show table_style">
          <h5>خدمه العملاء</h5>
          <DataGridDemo
            selectionRows={selectionRows}
            coulmns={coulmns}
            rows={marketers}
            handleRowSelection={handleRowSelection}
          />
        </div>
      </div>
    </>
  );
}

export default TeamLeaderHome;
