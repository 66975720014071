import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import App from "./App";
import Dashboard from "./routes/Dashboard";
import Home from "./routes/Home";
import "./css/style.css";
import Courses from "./routes/Courses";
import CoursesRigister from "./routes/CoursesRigister";
import SearchForCourse from "./routes/SearchForCourse";
import CourseComplete from "./routes/CourseComplete";
import { Provider } from "react-redux";
import store from "./store";
import AddNewCourse from "./routes/AddNewCourse";
import EditCourse from "./routes/EditCourse";
import Login from "./routes/Login";
import CustomerServices from "./routes/CustomerServices";
import AddNewCustomer from "./routes/AddNewCustomer";
import CustomerServicesCourse from "./routes/CustomerServicesCourse";
import RequestsHistory from "./routes/RequestsHistory";
import Markters from "./routes/Markters";
import MarketerRequests from "./routes/MarketerRequests";
import Users from "./routes/Users";
import AddNewUser from "./routes/AddNewUser";
import SuperVisor from "./routes/supervisor/SuperVisor";
import NotFound from "./routes/NotFound";
import EditMarkater from "./routes/EditMarkater";
import TeamLeader from "./routes/teamleader/TeamLeader";
import TeamLeaderHome from "./routes/teamleader/TeamLeaderHome";
import TeamLeaderLogin from "./routes/teamleader/TeamLeaderLogin";
import TeamLeaderStatics from "./routes/teamleader/TeamLeaderStatics";
import TeamLeaderData from "./routes/teamleader/TeamLeaderData";
import CustomerServiceLogin from "./routes/customerService/CustomerServiceLogin";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },

  {
    path: "/dashboard",
    element: <Dashboard />,
    children: [
      { index: true, element: <Home /> },
      { path: "courses", element: <Courses /> },
      { path: "add-new-course", element: <AddNewCourse /> },
      { path: "edit-course/:id", element: <EditCourse /> },
      { path: "course-rigister", element: <CoursesRigister /> },
      { path: "search-for-course", element: <SearchForCourse /> },
      { path: "course-completed", element: <CourseComplete /> },
      { path: "customer-services", element: <CustomerServices /> },
      { path: "add-new-customer-services", element: <AddNewCustomer /> },
      {
        path: "customer-services-courses/:id",
        element: <CustomerServicesCourse />,
      },
      { path: "request-histories", element: <RequestsHistory /> },
      { path: "markaters", element: <Markters /> },
      { path: "markater-requests/:id", element: <MarketerRequests /> },
      { path: "all-users", element: <Users /> },
      { path: "add-user", element: <AddNewUser /> },
      { path: "edit-markater/:marketerId", element: <EditMarkater /> },
    ],
  },

  {
    path: "/supervisor",
    element: <SuperVisor />,
    children: [
      { index: true, element: <Home /> },
      { path: "courses", element: <Courses /> },
      { path: "course-rigister", element: <CoursesRigister /> },
      { path: "course-completed", element: <CourseComplete /> },
      { path: "customer-services", element: <CustomerServices /> },
      {
        path: "customer-services-courses/:id",
        element: <CustomerServicesCourse />,
      },
      { path: "request-histories", element: <RequestsHistory /> },
      { path: "markaters", element: <Markters /> },
      { path: "markater-requests/:id", element: <MarketerRequests /> },
      // { path: "all-users", element: <Users /> },
      // { path: "add-user", element: <AddNewUser /> },
      { path: "edit-markater/:marketerId", element: <EditMarkater /> },
    ],
  },

  {
    path: "/teamleader",
    element: <TeamLeaderLogin />,
  },
  {
    path: "/customer-service",
    element: <CustomerServiceLogin />,
  },
  {
    path: "/customer-service/dashboard",
    element: <></>,
  },
  {
    path: "/teamleader/dashboard",
    element: <TeamLeader />,
    children: [
      { index: true, element: <TeamLeaderStatics /> },
      { path: "team", element: <TeamLeaderHome /> },
      { path: "team-data/:id", element: <MarketerRequests /> },
      { path: "mydata", element: <TeamLeaderData /> },
    ],
  },

  {
    path: "*",
    element: <NotFound />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <RouterProvider router={routes} />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
