import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { Link, NavLink, useNavigate } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import HistoryIcon from "@mui/icons-material/History";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loading from "./Loading";
import Cookies from "js-cookie";

function TeamLeaderSidenav() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const usersprofile = useSelector(
  //   (state) => state.userProfileSlice.usersprofile
  // );
  const [userdata, setUserData] = useState({});
  const [loading, setLoading] = useState(false);

  const signOut = () => {
    setLoading(true);
    Cookies.remove("user_id");
    Cookies.remove("user_name");
    toast.success("تم تسجيل الخروج بنجاح");
    setLoading(false);
    navigate("/teamleader");
  };

  const handelBackDrop = () => {
    document
      .querySelector(".backdropmenu")
      .classList.remove("backdropmenu_active");
    document.querySelector(".side-nav").classList.remove("openmenu");
    // document.querySelector(".notification_component").classList.remove("notification_component_visible");
  };

  return (
    <>
      {loading ? <Loading open={true} /> : null}
      <div className="side-nav">
        <div className="cloe_nav" onClick={handelBackDrop}>
          <i className="fas fa-times"></i>
        </div>
        <div className="side_links">
          <ul>
            <li>
              <NavLink to="/teamleader/dashboard" end>
                <HomeOutlinedIcon></HomeOutlinedIcon>
                <span>الرئيسية</span>
              </NavLink>
            </li>

            <li>
              <NavLink to="/teamleader/dashboard/team" end>
                <PeopleOutlinedIcon></PeopleOutlinedIcon>
                <span>الفريق</span>
              </NavLink>
            </li>

            <li>
              <NavLink to="/teamleader/dashboard/mydata" end>
                <PeopleOutlinedIcon></PeopleOutlinedIcon>
                <span>بياناتي</span>
              </NavLink>
            </li>

            <li className="signout_btn">
              <a onClick={signOut}>
                <span>
                  <LogoutOutlinedIcon></LogoutOutlinedIcon>
                </span>
                <span>تسجيل الخروج</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default TeamLeaderSidenav;
