import React, { useEffect, useState } from "react";
import DataGridDemo from "../components/DataTable";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { coursesGetAll, deleteCourse } from "../store/CourseSlice";
import { toast } from "react-toastify";
import { Delete } from "@mui/icons-material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { deleteUser, getAllUsers } from "../store/usersSlice";
import Cookies from "js-cookie";

function Users() {
  const selectionRows = false;
  const dispatch = useDispatch();
  const users = useSelector((state) => state.usersSlice.users);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [loading, setLoading] = useState(true);
  const userRole = Cookies.get("user_role");

  useEffect(() => {
    dispatch(getAllUsers())
      .unwrap()
      .then((res) => {
        // toast.success('')
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setLoading(false);
      });
  }, [dispatch]);

  const handledelete = (id) => {
    confirmAlert({
      title: "تاكيد الحذف",
      message: "هل انت متاكد من ذلك",
      buttons: [
        {
          label: "تاكيد",
          onClick: () => {
            setLoading(true);
            dispatch(deleteUser(id))
              .unwrap()
              .then((data) => {
                toast.success("تم المسح بنجاح");
                setLoading(false);
              })
              .catch((error) => {
                toast.error(error.message);
                setLoading(false);
              });
          },
        },
        {
          label: "الغاء",
        },
      ],
    });
  };

  const coulmns = [
    // { field: "name", headerName: "الاسم", editable: true, width: 200 },
    { field: "userName", headerName: "اسم المستخدم", width: 200 },
    { field: "password", headerName: "كلمة المرور", width: 200 },
    {
      field: "role",
      headerName: "الصلاحيه",
      width: 200,
      renderCell: (params) => (
        <span>
          {" "}
          {params.row.role === 0
            ? "admin"
            : params.row.role === 1
            ? "supervisor"
            : null}{" "}
        </span>
      ),
    },
  ];

  if (userRole == 0) {
    coulmns.push({
      field: "actions",
      headerName: "اختيارات",
      // width: 150,
      renderCell: (params) => (
        <div className="tableoptions">
          {/* <span className="editicon">
            <Link to={`/dashboard/edit-course/${params.row.id}`}>
              <CreateOutlinedIcon style={{ cursor: "pointer" }} />
            </Link>
          </span> */}
          <span className="deleteicon">
            <Delete
              onClick={() => handledelete(params.row.id)}
              style={{ cursor: "pointer" }}
            />
          </span>
        </div>
      ),
    });
  }

  const handleRowSelection = (selectionModel) => {
    setRowSelectionModel(selectionModel);
    // setInputs((prevInputs) => ({ ...prevInputs, district_id: selectionModel }));
    // console.log("Selected Rows:", selectionModel);

    // const selectedRowData = selectionModel.map((selectedId) =>
    //   rows.find((row) => row.id === selectedId)
    // );
    // console.log("Selected Row Data:", selectedRowData);
  };

  return (
    <>
      {loading ? <Loading open={true} /> : null}
      <div className="manage_employee">
        <div className="add_button">
          {userRole == 0 ? (
            <Link to="/dashboard/add-user">اضافة مستخدم</Link>
          ) : null}
        </div>
        <div className="table_show table_style">
          <h5>المستخدمين</h5>
          <DataGridDemo
            selectionRows={selectionRows}
            coulmns={coulmns}
            rows={users}
            handleRowSelection={handleRowSelection}
          />
        </div>
      </div>
    </>
  );
}

export default Users;
